import React, { useState, useEffect } from "react";
import "./Navbar.css";
import navimage from "../../Assets/apnakonnectlogo.png";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const homepage = () => {
    navigate("/");
  };

  const installApp = () => {
    navigate("/web/installApp");
  };

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  return (
    <div className={`nav ${isScrolled ? "scrolled" : ""}`}>
      <div className="nav-logo" onClick={homepage}>
        <img className="nav-image" src={navimage} alt="navbar-image" />
      </div>
      <div className="nav-items"></div>
      <div style={{ display: "flex", gap: "24px" }}>
        <div className="nav-buttons">
          <Button
            onClick={installApp}
            sx={{
              display: "flex",
              height: "40px",
              padding: "10px 12px",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#000",
              color: "#FFFFFF",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "100%",
              letterSpacing: "0.5px",
              width: "120px", // Increased width for better visibility
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Added shadow effect
              "&:hover": {
                backgroundColor: "#333", // Darker shade on hover
              },
            }}
          >
            Download
          </Button>
        </div>
        <div
          className={`container ${isDropdownVisible ? "change" : ""}`}
          onClick={toggleDropdown}
        >
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>

        <div
          className={`dropdown-content ${isDropdownVisible ? "show" : ""}`}
          id="myDropdown"
        >
          <a href="/web/home">Home</a>
          <a href="/web/aboutus">About</a>
          <a href="/web/faqs">FAQs</a>
          <a href="/web/contact">Contact Us</a>
          <a href="/web/installApp">Download</a>
          <a href="/web/teamdetails">Team Details</a>
          <a href="/web/pricing">Services</a>
          <a href="/web/business-profile">Business Profile</a>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
