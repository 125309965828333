import React from "react";
import "./Footer.css";
import footer from "../../Assets/aklogofooter.png";
import apple from "../../Assets/apple-app-store-logo-removebg-preview.png";
import playStore from "../../Assets/play-store-logo.png";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-top">
        <div className="footer-top-image-container">
          <img className="footer-top-image" src={footer} />
        </div>
        {/* <form className="footer-top-right-container">
          <div className="footer-top-right-fields">
            <div className="right-input-filed-container">
              <input
                type="text"
                className="right-input-filed"
                placeholder="Enter your email to get updates"
              />
            </div>
            <div className="footer-right-button">
              <button className="footer-button">Subscribe</button>
            </div>
          </div>
        </form> */}
      </div>
      <hr className="line" />
      <div className="footer-columns">
        <div className="footer-column-1">
          <div className="footer-col-1-heading">
            <h1 className="footer-col-heading">Quick Links</h1>
          </div>
          <div className="footer-col-1-menus">
            <p className="footer-col-menu">
              <a href="/web/aboutus">About us</a>
            </p>
          </div>
          <div className="footer-col-1-menus">
            <p className="footer-col-menu">
              <a href="/web/policy">Privacy Policy</a>
            </p>
          </div>
          <div className="footer-col-1-menus">
            <p className="footer-col-menu">
              <a href="/web/terms">Terms of Service</a>
            </p>
          </div>
          <div className="footer-col-1-menus">
            <p className="footer-col-menu">
              <a href="/web/refund-policy">Refund / Cancellation Policy</a>
            </p>
          </div>
          <div className="footer-col-1-menus">
            <p className="footer-col-menu">
              <a href="/web/pricing">Pricing and Services</a>
            </p>
          </div>
        </div>
          {/* <div className="footer-column-2">
            <div className="footer-col-2-heading">
              <h1 className="footer-col-heading">Column Three</h1>
            </div>
            <div className="footer-col-2-para">
              <p className="footer-col-menu">
                specially curated platform dedicated for people todevelop
                meaningful interactions and valuable Konnections in the real
                world.
              </p>
            </div>
          </div> */}
        <div className="footer-column-3">
          <div className="col-3-top">
            <div className="footer-col-3-heading">
              <h1 className="footer-col-heading">GET CONNECT WITH US</h1>
            </div>
            <div className="button-groups">
              <div className="button-apple-container">
                <a href="https://apps.apple.com/in/app/apna-konnect/id1673410045">
                  <img className="logo-apple" src={apple} />
                </a>
              </div>
              <div className="button-playStore-container">
                <a href="https://play.google.com/store/apps/details?id=com.apnakonnect">
                  <img
                    className="logo-playStore"
                    src={playStore}
                    style={{ background: "black" }}
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-3-bottom">
            <div className="footer-col-3-heading">
              <h1 className="footer-col-heading">Join Us</h1>
            </div>
            <div className="social-buttons">
              <div className="social-icon-youtube">
                <a href="https://www.youtube.com/@apnakonnect0">
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.145 5.01711H6.47801C4.18801 5.01711 2.33301 6.85211 2.33301 9.11611V14.8841C2.33301 17.1481 4.18901 18.9841 6.47801 18.9841H18.145C20.435 18.9841 22.29 17.1481 22.29 14.8841V9.11611C22.29 6.85211 20.434 5.01611 18.145 5.01611V5.01711ZM15.342 12.2801L9.88501 14.8551C9.85172 14.8711 9.81493 14.8785 9.77804 14.8765C9.74115 14.8744 9.70538 14.8631 9.67404 14.8436C9.6427 14.824 9.61682 14.7968 9.59881 14.7646C9.58079 14.7323 9.57123 14.6961 9.57101 14.6591V9.35011C9.57167 9.31299 9.58173 9.27663 9.60023 9.24444C9.61873 9.21224 9.64508 9.18526 9.67683 9.16599C9.70857 9.14673 9.74468 9.13581 9.78178 9.13425C9.81888 9.1327 9.85577 9.14057 9.88901 9.15711L15.347 11.8921C15.3834 11.9102 15.4139 11.9383 15.435 11.9729C15.4562 12.0076 15.4671 12.0476 15.4666 12.0882C15.4661 12.1288 15.4541 12.1685 15.4321 12.2026C15.41 12.2367 15.3788 12.2639 15.342 12.2811V12.2801Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </div>
              <div className="social-icon-facebook">
                <a href="https://www.facebook.com/Apnakonnect">
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.37923 5.865V8.613H7.36523V11.973H9.37923V21.959H13.5132V11.974H16.2882C16.2882 11.974 16.5482 10.363 16.6742 8.601H13.5302V6.303C13.5302 5.96 13.9802 5.498 14.4262 5.498H16.6802V2H13.6162C9.27623 2 9.37923 5.363 9.37923 5.865Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </div>
              {/* <div className="social-icon-twitter">
                <a>
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.333 5.90692C21.5834 6.2343 20.7895 6.44896 19.977 6.54392C20.8298 6.04315 21.4711 5.24903 21.781 4.30992C20.973 4.78025 20.0917 5.11152 19.174 5.28992C18.7876 4.88513 18.3227 4.56331 17.8078 4.3441C17.2928 4.12489 16.7386 4.01289 16.179 4.01492C13.913 4.01492 12.076 5.82492 12.076 8.05492C12.076 8.37092 12.112 8.67992 12.182 8.97492C10.5566 8.89761 8.96512 8.48233 7.50918 7.75556C6.05323 7.02879 4.76477 6.0065 3.72601 4.75392C3.36183 5.36832 3.17042 6.0697 3.17201 6.78392C3.1727 7.45189 3.33984 8.10915 3.6583 8.69631C3.97676 9.28348 4.43651 9.78203 4.99601 10.1469C4.34549 10.1259 3.70903 9.95225 3.13801 9.63992V9.68992C3.13801 11.6479 4.55301 13.2809 6.42801 13.6529C6.07562 13.7464 5.71259 13.7938 5.34801 13.7939C5.08301 13.7939 4.82601 13.7689 4.57501 13.7189C4.84309 14.5268 5.35611 15.2312 6.04283 15.7343C6.72954 16.2373 7.55585 16.514 8.40701 16.5259C6.94708 17.6505 5.15483 18.258 3.31201 18.2529C2.98001 18.2529 2.65401 18.2329 2.33301 18.1969C4.21426 19.3876 6.3956 20.0182 8.62201 20.0149C16.169 20.0149 20.295 13.8579 20.295 8.51892L20.281 7.99592C21.0859 7.42959 21.7812 6.72177 22.333 5.90692Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </div> */}
              <div className="social-icon-instagram">
                <a href="https://www.instagram.com/apnakonnect/">
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.35 2H8.28001C6.70316 2.00185 5.19144 2.62914 4.07654 3.74424C2.96163 4.85933 2.3346 6.37115 2.33301 7.948L2.33301 16.018C2.33486 17.5948 2.96215 19.1066 4.07724 20.2215C5.19234 21.3364 6.70416 21.9634 8.28101 21.965H16.351C17.9279 21.9631 19.4396 21.3359 20.5545 20.2208C21.6694 19.1057 22.2964 17.5938 22.298 16.017V7.947C22.2962 6.37015 21.6689 4.85844 20.5538 3.74353C19.4387 2.62862 17.9269 2.00159 16.35 2V2ZM20.29 16.017C20.29 16.5344 20.1881 17.0468 19.9901 17.5248C19.7921 18.0028 19.5019 18.4371 19.136 18.803C18.7701 19.1689 18.3358 19.4591 17.8578 19.6571C17.3798 19.8551 16.8674 19.957 16.35 19.957H8.28001C7.23523 19.9567 6.23333 19.5415 5.49465 18.8026C4.75598 18.0638 4.34101 17.0618 4.34101 16.017V7.947C4.34127 6.90222 4.7565 5.90032 5.49536 5.16165C6.23423 4.42297 7.23623 4.008 8.28101 4.008H16.351C17.3958 4.00827 18.3977 4.42349 19.1364 5.16235C19.875 5.90122 20.29 6.90322 20.29 7.948V16.018V16.017Z"
                      fill="white"
                    />
                    <path
                      d="M12.3153 6.81909C10.9467 6.82121 9.63485 7.36588 8.66722 8.3337C7.69959 9.30152 7.15517 10.6135 7.15332 11.9821C7.15491 13.351 7.69934 14.6634 8.66722 15.6315C9.6351 16.5996 10.9474 17.1442 12.3163 17.1461C13.6854 17.1445 14.998 16.5999 15.9661 15.6318C16.9342 14.6637 17.4787 13.3512 17.4803 11.9821C17.4782 10.6132 16.9333 9.30098 15.965 8.33329C14.9968 7.3656 13.6842 6.82141 12.3153 6.82009V6.81909ZM12.3153 15.1381C11.4786 15.1381 10.6761 14.8057 10.0844 14.214C9.49272 13.6223 9.16032 12.8198 9.16032 11.9831C9.16032 11.1463 9.49272 10.3438 10.0844 9.75217C10.6761 9.16049 11.4786 8.82809 12.3153 8.82809C13.1521 8.82809 13.9546 9.16049 14.5462 9.75217C15.1379 10.3438 15.4703 11.1463 15.4703 11.9831C15.4703 12.8198 15.1379 13.6223 14.5462 14.214C13.9546 14.8057 13.1521 15.1381 12.3153 15.1381Z"
                      fill="white"
                    />
                    <path
                      d="M17.489 8.09509C18.1721 8.09509 18.726 7.54127 18.726 6.85809C18.726 6.17492 18.1721 5.62109 17.489 5.62109C16.8058 5.62109 16.252 6.17492 16.252 6.85809C16.252 7.54127 16.8058 8.09509 17.489 8.09509Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </div>
              <div className="social-icon-linkdin">
                <a href="https://www.linkedin.com/company/apnakonnect/">
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.292 13.7189V21.0979H18.014V14.2129C18.014 12.4829 17.395 11.3029 15.847 11.3029C14.665 11.3029 13.961 12.0989 13.652 12.8679C13.539 13.1429 13.51 13.5259 13.51 13.9109V21.0979H9.23001C9.23001 21.0979 9.28801 9.43788 9.23001 8.22888H13.51V10.0529L13.482 10.0949H13.51V10.0529C14.078 9.17788 15.093 7.92688 17.366 7.92688C20.181 7.92688 22.292 9.76688 22.292 13.7189ZM4.75401 2.02588C3.29101 2.02588 2.33301 2.98588 2.33301 4.24888C2.33301 5.48388 3.26301 6.47288 4.69801 6.47288H4.72601C6.21901 6.47288 7.14601 5.48388 7.14601 4.24888C7.12001 2.98588 6.22001 2.02588 4.75501 2.02588H4.75401ZM2.58701 21.0979H6.86501V8.22888H2.58701V21.0979Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="line" />
      <div className="footer-bottom">
        <p className="bottom-text-left">
        Copyright © 2024 Resshu Connect Pvt. Ltd. All Rights Reserved.
        <br/>
        Corporate office : Resshu Connect Private Limited, C 607 , Titanium City Center , 100 feet road, Satellite, Ahmedabad, Gujarat 380015
        </p>
        {/* <div className="bottom-menus">
          <div className="bottom-menu-1">
            <p className="footer-bottom-menu">Eleven</p>
          </div>
          <div className="bottom-menu-2">
            <p className="footer-bottom-menu">Twelve</p>
          </div>
          <div className="bottom-menu-3">
            <p className="footer-bottom-menu">Thirteen</p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Footer;
