import React, { useState } from "react";
import people from "../../../Assets/MainScreen/meetpeople.avif";
import "./meetpeople.css";
import ReactPlayer from 'react-player/youtube';
//

const MeetPeople = () => {
  const [showVideo, setShowVideo] = useState(false);

  const openVideo = () => {
    setShowVideo(true);
  }

  const closeVideo = () => {
    setShowVideo(false);
  }

  return (
    <div className="meet-people">
      <div className="meet-people-container">
        <img src={people} className="mp-image" />
        <div>
          <h1 className="mp-heading">What you can find at APNA KONNECT ?</h1>
          <div>
            <br></br>
          <ul  class="apna-konnect-list">
              <li><strong>Meet New People:</strong> Connect with neighbors and expand your social circle.</li>
              <li><strong>Local Offers:</strong> Discover exclusive deals from nearby businesses and services.</li>
              <li><strong>Interest-Based Communities:</strong> Join or create groups focused on hobbies and activities.</li>
              <li><strong>Local Events:</strong> Stay informed about upcoming events and activities in your area.</li>
              <li><strong>Real Neighborhood Connection:</strong> Experience genuine connections and camaraderie in your community.</li>
          </ul>
          </div>

          {/* <button className="btn-show-less Acc-button" style={{background:"#00AC73", color:"#fff"}} onClick={openVideo}>Join Apna Konnect</button> */}
        </div>
      </div>
      {showVideo && (
        <div className="video-modal">
          <div className="video-modal-content">
            <ReactPlayer
              url="https://youtube.com/shorts/7Zh0UHj__Jo?si=XmVAidooPF0qPEWp"
              controls
              width="100%"
              height="100%"
            />
            <button className="btn btn-outline-secondary close-button" onClick={closeVideo}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#00ac73" className="bi bi-x" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MeetPeople;
