import React from "react";
import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar/Navbar";

const TermOfService = () => {
  return (
    <div className="back-color">
      <Navbar />
      <div className="rc">
        <div className="rc-header">
          <h1 className="rc-h1">Terms of Service</h1>
        </div>
        <div>
          <div style={{ borderBottom: "1px solid #225c7b" }}>
            <h3 className="rc-h3">Introduction</h3>
            <p className="rc-p">
              1.1 These terms of service (the “Terms”) govern your access to and
              use of Apna Konnect website, Apna Konnect Mobile App, Apna Konnect
              Desktop App and any other services (the “services”) provided by
              the Apna Konnect Platform, (“we” or “our” or the “Apna Konnect”),
              so please carefully read them before using the Services.
            </p>
            <p className="rc-p">
              1.2 By using the Services you agree to be bound by these Terms. If
              you are using the Services on behalf of an organization, you are
              agreeing to these Terms for that organization and promising that
              you have the authority to bind that organization to these terms.
              In that case, “you” and “your” will refer to that organization.
            </p>
            <p className="rc-p">
              1.3 You may use the Services only in compliance with these Terms.
              You may use the Services only if you have the power to form a
              contract with Apna Konnect and are not barred under any applicable
              laws from doing so. The Services may continue to change over time
              as we refine and add more features. We may stop, suspend, or
              modify the Services at any time without prior notice to you. We
              may also remove any content from our Services at our discretion.
            </p>
          </div>
          <h3 className="rc-h3">Your Content and Content of Others</h3>
          <p className="rc-p">
            2.1 By using our Services you provide us with information, ideas,
            files and pictures, that you submit to Apna Konnect (together, “your
            content”). You retain full ownership to your content. We don’t claim
            any ownership to any of it. These Terms do not grant us any rights
            to your content or intellectual property except for the limited
            rights that are needed to run the Services, as explained below. You
            are responsible for your content, which means any information,
            material, or other content posted to our Platform. Your content must
            comply with our Terms of Service.
          </p>
          <p className="rc-p">
            2.2 We do not own the Content that you post. However, we do require
            that you provide us a license to use this Content in order for us to
            operate, improve, promote, and protect Apna Konnect and our Platform
            for the benefit of you and others. We may need your permission to do
            things you ask us to do with your stuff, for example, hosting your
            idea, or sharing them at your direction. This includes product
            features visible to you, it also includes design choices we make to
            technically administer our Services, for example, how we redundantly
            backup data to keep it safe. You give us the permissions we need to
            do those things solely to provide the Services. This permission also
            extends to trusted third parties we work with to provide the
            Services.
          </p>
          <p className="rc-p">
            2.3 Aside from the rare exceptions we identify in our Privacy
            Policy, no matter how the Services change, we won’t share private
            content with others, except when ordered by a court, for any purpose
            unless you direct us to. How we collect and use your information
            generally is also explained in our Privacy Policy.
          </p>

          <p className="rc-p">
            2.3 Aside from the rare exceptions we identify in our Privacy
            Policy, no matter how the Services change, we won’t share private
            content with others, except when ordered by a court, for any purpose
            unless you direct us to. How we collect and use your information
            generally is also explained in our Privacy Policy.
          </p>
          <p className="rc-p">
            2.4 We are not responsible for Content that members post or the
            communications that members send using our Platform. We generally
            don’t review Content before it’s posted. If you see Content that
            violates our Terms of Service, you may report inappropriate Content
            to us. You are solely responsible for your conduct, the content of
            your ideas, files, plans pictures, and your communications with
            others while using the Services. For example, it’s your
            responsibility to ensure that you have the rights or permission
            needed to comply with these Terms.
          </p>
          <p className="rc-p">
            2.5 Public content is available to be viewed by anyone who visits
            the Apna Konnect platform and we cannot control what they choose to
            do on viewing it.
          </p>
          <p className="rc-p">
            2.6 We may choose to review public content for compliance with our
            Community Guidelines, but you acknowledge that Apna Konnect has no
            obligation to monitor any information on the Services. We are not
            responsible for the accuracy, completeness, appropriateness, or
            legality of files, user posts, or any other information you may be
            able to access using the Services.
          </p>

          <h3 className="rc-h3">Sharing Content</h3>

          <p className="rc-p">
            The Services provide features that allow you to share your idea with
            others or to make it public. There are many things that users may do
            with that idea (for example, copy it, modify it, re-share it).
            Please consider carefully what you choose to share or make your idea
            public. Apna Konnect has no responsibility for that activity. You
            also agree that your profile on Apna Konnect will be visible to all
            users, except some private settings like Account Settings and
            Payment Information etc.
          </p>
          <h3 className="rc-h3">Eligibility</h3>

          <p className="rc-p">
            4.1 To use the Services, you agree that: (1) you must be the
            “Minimum Age” (defined below) or older; (2) you will only have one
            Apna Konnect account, which must be in your real name; and (3) you
            are not already restricted by Apna Konnect from using the Services.
          </p>
          <p className="rc-p">
            4.2 “Minimum Age” means (a) 18 years old for the People’s Republic
            of China, (b) 16 years old for the Netherlands, (c) 14 years old for
            the United States, Canada, Germany, Spain, Australia and South
            Korea, and (d) 13 years old for all other countries. However, if law
            requires that you must be older in order for Apna Konnect to
            lawfully provide the Services to you (including the collection,
            storage and use of your information) then the Minimum Age is such
            older age. The Services are not for use by anyone under the age of
            13.
          </p>
          <h3 className="rc-h3">Account Security</h3>
          <p className="rc-p">
            You are responsible for safeguarding the password that you use to
            access the Services and you agree not to disclose your password to
            any third party. You are responsible for any activity using your
            account, whether or not you authorized that activity. You should
            immediately notify Us of any unauthorized use of your account. You
            acknowledge that if you wish to protect your transmission of data or
            files to Apna Konnect, it is your responsibility to use a secure
            encrypted connection to communicate with the Services.
          </p>

          <h3 className="rc-h3">Software</h3>
          <p className="rc-p">
            Some use of our Service may require you to download a client
            software package or Mobile Phone App (“Software”). We hereby grant
            you a limited, nonexclusive, nontransferable, revocable license to
            use the Software, solely to access the Services. Your license to use
            the Software is automatically revoked if you violate these Terms in
            a manner that implicates our intellectual property rights. We hereby
            reserve all rights not expressly granted in these Terms. You must
            not reverse engineer or decompile the Software, nor attempt to do
            so, nor assist anyone else to do so. Our Services may update the
            Software on your device automatically when a new version is
            available.
          </p>

          <h3 className="rc-h3">Acceptable Use</h3>
          <p className="rc-p">
            You will not, and will not attempt to, misuse the Services, and will
            use the Services only in a manner consistent with the laws in your
            country. You also agree to follow the Community Guidelines, posted
            within every community, you join on Apna Konnect Platform. This
            contract and all its components will get precedence over any
            individual Community Guidelines.
          </p>

          <h3 className="rc-h3">Third Party Content</h3>
          <p className="rc-p">
            The Services may contain links to third-party websites or resources.
            Apna Konnect does not endorse and is not responsible or liable for
            their availability, accuracy, the related content, products, or
            services. You are solely responsible for your use of any such
            websites or resources.
          </p>

          <h3 className="rc-h3">Interactions with other Users</h3>
          <p className="rc-p">
            When inviting other users to your idea or engaging with them in any
            kind of deal, you are solely responsible for any legal documentation
            to protect your interests. Apna Konnect does not offer any
            guarantees on the authenticity of users’ credentials. We strongly
            suggest that you perform your own due diligence before entering into
            any agreement with another Apna Konnect user and properly document
            any understanding between the parties.
          </p>

          <h3 className="rc-h3">Termination of Service</h3>
          <p className="rc-p">
            10.1 Though we’d much rather you stay, you can stop using our
            Services any time. We reserve the right to suspend or end the
            Services at any time, with or without cause, and with or without
            notice. For example, we may suspend or terminate your use if you are
            not complying with these Terms, or use the Services in any way that
            would cause us legal liability or disrupt others’ use of the
            Services. If we suspend or terminate your use, we will try to let
            you know in advance and help you retrieve data, though there may be
            some cases (for example, repeatedly or flagrantly violating these
            Terms, a court order, or danger to other users) where we may suspend
            immediately.
          </p>
          <p className="rc-p">
            10.2 Administrator of independent communities, hosted on Apna
            Konnect platform, have full right to suspend or terminate the
            membership of any user, with or without cause. Termination from one
            community will not automatically result in terminating your account
            on Apna Konnect. Apna Konnect has no control over who is given
            access or not on any of the communit.
          </p>
          <h3 className="rc-h3">Jurisdiction</h3>
          <p className="rc-p">
            11.1 In the event of any dispute, claim, question, or disagreement
            arising from or relating to this agreement or the breach thereof,
            and you are a resident of India, both you and us hereto shall use
            our best efforts to settle the dispute, claim, question, or
            disagreement. To this effect, you and us shall consult and negotiate
            with each other in good faith and, recognizing our mutual interests,
            attempt to reach a just and equitable solution satisfactory to both
            of us. If you and us do not reach such solution within a period of
            60 days, then, upon notice by either party to the other, all
            disputes, claims, questions, or differences shall be finally settled
            by arbitration administered in India in accordance with the
            provisions of any applicable Arbitration Rules in India.
          </p>
          <p className="rc-p">
            11.2 If we don’t act to enforce a breach of this Agreement, that
            does not mean that Apna Konnect has waived its right to enforce this
            Agreement. You may not assign or transfer this Agreement (or your
            membership or use of Services) to anyone without our consent.
            However, you agree that Apna Konnect may assign this Agreement to
            its affiliates or a party that buys it without your consent. There
            are no third-party beneficiaries to this Agreement.
          </p>
          <p className="rc-p">
            11.3 We reserve the right to change the terms of this Agreement and
            will provide you notice if we do and we agree that changes cannot be
            retroactive. If you don’t agree to these changes, you must stop
            using the Services.
          </p>

          <h3 className="rc-h3">Premium Accounts</h3>
          <p className="rc-p">
            <span style={{ color: "#000", fontWeight: "700" }}>
              12.1 Billing :{" "}
            </span>
            <a href="">
              Some aspects of the Services are provided free of charge and some
              require a paid subscription (“Premium Account”). You can add Apna
              Konnect paid features to your account by subscribing any of the
              Premium Packages, listed on the pricing page at (website page).
              We'll automatically bill you from the date you convert to a
              Premium Account and on each periodic renewal until cancellation.
              You're responsible for all applicable taxes, and we'll charge tax
              when required to do so.{" "}
            </a>
          </p>
          <p className="rc-p">
            <span style={{ color: "#000", fontWeight: "700" }}>
              12.2 No Refunds :{" "}
            </span>
            <a href="">
              You may cancel your Apna Konnect Premium Account at any time but
              you won't be issued a refund unless it's legally required.{" "}
            </a>
          </p>
          <p className="rc-p">
            <span style={{ color: "#000", fontWeight: "700" }}>
              12.3 Downgrades :{" "}
            </span>
            <a href="">
              Your Premium Account will remain in effect until it's cancelled or
              terminated under these Terms. If you don't pay for your Premium
              Account on time, we reserve the right to suspend it or reduce your
              access to free features.{" "}
            </a>
          </p>
          <p className="rc-p">
            <span style={{ color: "#000", fontWeight: "700" }}>
              12.4 Fee Changes :{" "}
            </span>
            <a href="">
              We may change the fees in effect but will give you advance notice
              of these changes via a message to the email address associated
              with your account{" "}
            </a>
          </p>
          <p className="rc-p">
            <span style={{ color: "#000", fontWeight: "700" }}>
              12.5 Payments :{" "}
            </span>
            <a href="">
              If you purchase any of our Premium Account, you agree to pay us
              the applicable fees and taxes. Failure to pay these fees may
              result in the termination of your subscription. Also:{" "}
            </a>
          </p>
          <p className="rc-p">
            Your purchase may be subject to foreign exchange fees or differences
            in prices based on location (e.g. exchange rates).
          </p>
          <p className="rc-p">
            You authorize us to store and continue billing your payment method
            (e.g. credit card) even after it has expired, to avoid interruptions
            in your service and to facilitate easy payment for new services.
          </p>
          <p className="rc-p">
            You must pay us for applicable fees and taxes unless you cancel the
            Premium Service, in which case you agree to still pay these fees
            through the end of the applicable subscription period.
          </p>
          <p className="rc-p">
            Taxes are calculated based on the billing information that you
            provide us at the time of purchase.
          </p>
          <h3 className="rc-h3">Limitation of Liability</h3>
          <p className="rc-p">
            To the fullest extent permitted by law, in no event will Apna
            Konnect, its affiliates, officers, employees, agents, suppliers or
            licensors be liable for
            <p className="rc-p">
              (a) Any indirect, special, incidental, punitive, exemplary or
              consequential (including loss of use, data, business, or profits)
              damages, regardless of legal theory, whether or not apna konnect
              has been warned of the possibility of such damages, and even if a
              remedy fails of its essential purpose;
            </p>
            <p className="rc-p">
              (b) Aggregate liability for all claims relating to the services
              more than the greater of (amount of fee) or the amounts paid by
              you to apna konnect within the past three months of the services
              in question, from the date of filing such claim.
            </p>
          </p>
          <h3 className="rc-h3">Updates to Terms of Service</h3>
          <p className="rc-p">
            We may revise these Terms from time to time and the most current
            version will always be posted on our website. If a revision, in our
            sole discretion, is material we will notify you (for example via
            email to the email address associated with your account). Other
            changes may be posted to our blog or terms page, so please check
            those pages regularly. By continuing to access or use the Services
            after revisions become effective, you agree to be bound by the
            revised Terms. If you do not agree to the new terms, please stop
            using the Services.
          </p>
          <h3 className="rc-h3">Miscellaneous</h3>
          <p className="rc-p">
            We try hard to make sure that our Platform is always available and
            working, but we cannot guarantee it will be. Occasionally things may
            not go exactly as planned. We apologize in advance for any
            inconvenience.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermOfService;
