import React from "react";
import "../layouts/Landing.css";
import Navbar from "../Common/Navbar/Navbar";
import Header from "./MainScreen/Header";
import Footer from "../Common/Footer/Footer";
import Features from "./MainScreen/Features/Features";
import Accordion from "./MainScreen/Accordion/Accordion";
import Testimonials from "./MainScreen/Testimonials/Testimonials";
import UserGroups from "./MainScreen/UserGroups/usergroups";
import GetStarted from "./MainScreen/GetStarted/GetStarted";
import Hero from "./MainScreen/Hero/Hero";
import Highlight from "./MainScreen/Highlight/Highlight";
import MeetPeople from "./MainScreen/meetpeople/meetpeople";
import Nearby from "./MainScreen/Nearby/nearby";
import Marketplace from "./MainScreen/Marketplace/Marketplace";

const Landing = () => {
  return (
    <div>
      <Navbar />
      {/* <Header /> */}
      <Hero />
      <MeetPeople />
      <Highlight />
      <GetStarted />
      <Marketplace />
      <Nearby />
      <Features />
      <UserGroups />
      <Testimonials />
      {/* <Accordion /> */}
      {/* <Footer_download /> */}
      <Footer />
    </div>
  );
};

export default Landing;
