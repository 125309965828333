import React, { useState, useEffect } from "react";
import Samir from "../../Assets/Aboutus/Samir.png";
import Reena from "../../Assets/Aboutus/Reena.png";
import Shubh from "../../Assets/Aboutus/shubh.png";
import Abhay from "../../Assets/Aboutus/abhay.png";
import Dipp from "../../Assets/Aboutus/dipp.png";

import Logo from "../../Assets/Aboutus/about-us.png";
import Navbar from "../../Common/Navbar/Navbar";
import Footer from "../../Common/Footer/Footer";
import Rec2 from "../../Assets/rec.jpg";
import Rec1 from "../../Assets/Aboutus/rec-2.png";

// Import Swiper React component
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper/modules";
import "./Aboutus.css";
import { Carousel } from "react-bootstrap";
import { BiSolidQuoteAltLeft } from "react-icons/bi";

import "bootstrap/dist/css/bootstrap.min.css";

const AboutUs = () => {
 const [track, setTrack] = useState(0);

 const ownerDetails = [
  {
   id: 1,
   Image: Samir,
   name: "Samir Pachauri",
   position: "Founder",
   words:
    "worked in BFSI for over 25 years in various leadership roles in India's leading banks. Led multiple businesses at the country level. impeccable record of building businesses from scratch to become key market players in retail, wholesale, government, urban, and rural segments and markets.",
  },
  {
   id: 2,
   Image: Abhay,
   name: "Abhay Sharma",
   position: "Independent Director",
   words: "Abhay Sharma, Engineering graduate with PMP certification has 25+ years of experience. With global customers, he started his career with Hindustan Aeronautics Limited India and was exposed to transnational experience in North America and Europe. In his international experience, he led several large-sized business opportunities successfully and is currently located in France with the capacity of Senior Vice President at AXISCADES Technologies Limited."
  },
  {
   id: 3,
   Image: Reena,
   name: "Reena Pachauri",
   position: "Co-Founder",
   words:
    " A platform that helps us to connect with real-world and re-ignite the passion for serving and contributing to the community .",
  },
  {
   id: 4,
   Image: Shubh,
   name: "Shubh Pachauri",
   position: "Co-Founder",
   words: " Lets go local rather than global. ",
  },
 ];
 
 const data = [
  {
   id: "01.",
   heading: "An Idea, a Need ",
   headingSpan: "and",
   heading2: "a Story Untold",
   subheading:
    "As we move into a future built on virtualization and digitalization, we are starting to lose our sense of the real world, opting instead for virtual friends and connections. The allure of the virtual world has led to a growing addiction, distancing us from authentic human interactions. Our mission is to revive the meet-and-greet culture, encouraging people to socialize and form genuine connections in the real world.",
   subheadingSpan: "- As we come ",
  },
  {
   id: "02.",
   heading: "Konnect, share",
   headingSpan: "and",
   heading2: "Discover",
 
   subheading:
    "India's first hyperlocal community app, Konnect, helps you connect with your locality and local bussines, communities and services. We provide a platfrom where you can be a part of your digital neighbourhood",
   subheadingSpan: "- As we come ",
  }
 ];

 useEffect(() => {
  const interval = setInterval(() => {
   setTrack((prevTrack) => (prevTrack + 1) % data.length);
  }, 10000); // Switches data every 15 seconds

  return () => clearInterval(interval); // Cleanup interval on unmount
 }, []);

 return (
  <>
   <Navbar />
   <div className="about-us-container">
    <div className="a_upper-section">
     <div className="decore">
      <svg
       width="1119"
       height="600"
       viewBox="0 0 1119 616"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
      >
       <path
        d="M35.834 407.094C-29.0648 296.551 8.79282 71.8245 35.834 -26.7211L1127.39 -189L1231 247.225L1169.15 1212.86C1042.78 1214.47 760.966 1202.42 644.662 1141.37C499.282 1065.05 565.145 942.132 444.664 877.863C324.184 813.594 393.259 644.085 351.493 579.816C309.726 515.547 116.957 545.272 35.834 407.094Z"
        fill="#CCD7B5"
        fill-opacity="0.7"
       />
      </svg>
     </div>
     <div className="swiper-about-container">
     <Swiper
  navigation={true}
  modules={[Navigation]}
  autoHeight={true} // Add this line
  autoplay={{delay: 5000,stopOnLastSlide:false}}
  className="a_swiper"
>
  {ownerDetails.map((owner) => (
    <SwiperSlide key={owner.id} className="testimonial-box">
      <div>
        <img src={owner.Image} alt="" className="aboutus-image" />
      </div>
      <div className="owner-details">
        <div className="owner-name">{owner.name}</div>
        <div className="owner-type">{owner.position}</div>
      </div>
      <p className="talk-text">{owner.words}</p>
      <div className="logo-about">
        <img src={Logo} alt="" className="logo-image-about" />
        <div>
          <p className="logo-text">APNA KONNECT</p>
        </div>
      </div>
    </SwiperSlide>
  ))}
</Swiper>

     </div>
    </div>
    <div className="a_bottom-container">
     <div className="a_bottom-section">
      <div className="a_numer-container">
       <p className="a_bottom-number">{data[track].id}</p>
      </div>
      <div className="a_heading-container">
       <p className="a_heading">
        {data[track].heading}{" "}
        <span className="a_and">{data[track].headingSpan} </span> <br />
        {data[track].heading2}
       </p>
       <p className="a_subheading">{data[track].subheading}</p>
      </div>
      <div className="rectangle-container">
       {data.map((item, index) => (
        <button
         key={index}
         onClick={() => setTrack(index)}
         style={{
          borderColor: track === index ? "#5B9092" : "#ABB497",
          borderWidth: "7px",
         }}
        >
         <div className="rectangle"></div>
        </button>
       ))}
      </div>
     </div>

     <div className="a_image-container">
      <div>
       <img src={Rec1} className="rec1" alt="about" />
      </div>
      <div>
       <img src={Rec2} className="rec2" alt="about" />
      </div>
     </div>
    </div>
   </div>
   <div className="dipptext">
   We are DPIIT-Recognized.
   </div>
   <div className="dippcontainer">
   <div className="dippimage">
    <img src={Dipp}></img>
   </div>
   </div>
   <Footer />
  </>
 );
};

export default AboutUs;