// import React, { useRef, useState } from "react";
// // Import Swiper React components
// import { Swiper, SwiperSlide } from "swiper/react";

// // Import Swiper styles
// import "swiper/css";
// import "swiper/css/pagination";

// import "./Testimonials.css";

// // import required modules
// import { Pagination } from "swiper/modules";
// const Testimonials = () => {
//   return (
//     <div>
//       {" "}
//       <Swiper
//         initialSlide={2}
//         slidesPerView={3}
//         spaceBetween={30}
//         pagination={{
//           clickable: true,
//         }}
//         modules={[Pagination]}
//         className="mySwiper"
//       >
//         <SwiperSlide>Slide 1</SwiperSlide>
//         <SwiperSlide>
//           <div className="active-slider">
//             <div className="active-slider-upper">
//               <div className="active-slider-active-icon">
//                 <svg
//                   width="68"
//                   height="73"
//                   viewBox="0 0 68 73"
//                   fill="none"
//                   xmlns="http://www.w3.org/2000/svg"
//                 >
//                   <path
//                     fill-rule="evenodd"
//                     clip-rule="evenodd"
//                     d="M13.0275 22.6861C14.111 22.4243 15.2185 22.2925 16.3294 22.2931C25.1269 22.2931 32.2566 30.4411 32.2566 40.4881C32.2566 50.5381 25.1269 58.6861 16.3294 58.6861C7.53191 58.6861 0.399414 50.5411 0.399414 40.4881C0.399414 40.1941 0.405039 39.8971 0.419102 39.6031H0.399414C0.399414 24.4381 11.1994 12.0991 24.4744 12.0991V18.2071C20.1685 18.2071 16.1972 19.8811 13.0275 22.6861ZM48.6732 22.6861C49.7363 22.4281 50.8388 22.2931 51.9694 22.2931C60.7669 22.2931 67.8994 30.4411 67.8994 40.4881C67.8994 50.5381 60.7669 58.6861 51.9694 58.6861C43.1719 58.6861 36.0422 50.5411 36.0422 40.4881C36.0422 40.1941 36.0479 39.8971 36.0619 39.6031H36.0422C36.0422 24.4381 46.8422 12.0991 60.1172 12.0991V18.2071C55.8085 18.2071 51.8428 19.8811 48.6732 22.6861Z"
//                     fill="#FF7E1D"
//                   />
//                 </svg>
//               </div>
//               <div className="active-slider-active-text">
//                 <p className="text-slider-active">
//                   I came to learn that the <br />
//                   storyline in my head …<br />
//                   <span>was holding me back.</span>
//                 </p>
//               </div>
//             </div>
//             <div className="active-slider-bottom">
//               <div className="active-slider-active-name">
//                 <p className="slider-bottom-name">
//                   Peter, CEO at ApnaKonnect <br />
//                   <span style={{ display: "flex" }}>
//                     User since 14th Apr' 2022
//                   </span>
//                 </p>
//               </div>
//             </div>
//           </div>
//         </SwiperSlide>
//         <SwiperSlide>Slide 3</SwiperSlide>
//       </Swiper>
//     </div>
//   );
// };

// export default Testimonials;
import React, { useEffect, useRef, useState } from "react";
import { FcPrevious, FcNext } from "react-icons/fc";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./Testimonials.css";

// Import required modules
import { Pagination } from "swiper/modules";
import { BiSolidQuoteAltLeft } from "react-icons/bi";
const testimonialsData = [
  {
    id: 1,
    text: "It's a very useful app to grow your business in your locality. Great app and great customer service.",
    author: "Vaghela Mohit",
    joinDate: "Apr 28, 2024",
  },
  {
    id: 2,
    text: "The best app for business owners to get new clients in their own area.",
    author: "Dhiral Makwana",
    joinDate: "Apr 24, 2024",
  },
  {
    id: 3,
    text: "Apna Konnect is a fantastic platform that has truly brought our community together!",
    author: "Reintech Sales",
    joinDate: "Mar 19, 2024",
  },
  {
    id: 4,
    text: "This app helps a lot with expanding the business.",
    author: "Pavan Novelty",
    joinDate: "Apr 24, 2024, ",
  },
  // Add more testimonials data as needed
];

const Testimonials = () => {
  const [slidesPerView, setSlidesPerView] = useState(getSlidesPerView);

  function getSlidesPerView() {
    if (window.innerWidth >= 1201) {
      return 3; // Show all cards on big screens
    } else if (window.innerWidth >= 769) {
      return 2; // Show 2 cards on tablets
    } else {
      return 1; // Show 1 card on mobile
    }
  }

  const handleResize = () => {
    setSlidesPerView(getSlidesPerView());
  };

  // Update the slidesPerView when the window is resized
  window.addEventListener("resize", handleResize);
  const testimonialsDataLength = testimonialsData.length;
  const middleSlideIndex = Math.floor(testimonialsDataLength / 2);

  const [activeSlide, setActiveSlide] = useState(middleSlideIndex);
  const swiperRef = useRef(null);
  const handleNextSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      const swiper = swiperRef.current.swiper;
      if (swiper.isEnd) {
        // Delay the navigation to the first slide slightly to avoid abruptness
        setTimeout(() => {
          swiper.slideTo(0); // Navigate to the first slide
        }, 1000);
      } else {
        swiper.slideNext(); // Move to the next slide
      }
    }
  };

  const handlePrevSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleSlideChange = (swiper) => {
    setActiveSlide(swiper.activeIndex);
  };
  const [autoplayDelay, setAutoplayDelay] = useState(5000); // 5000 milliseconds (5 seconds)

  useEffect(() => {
    // Function to start the autoplay timer
    const startAutoplay = () => {
      const autoplayTimer = setInterval(() => {
        handleNextSlide();
      }, autoplayDelay);
      return autoplayTimer;
    };

    // Start the autoplay timer when the component mounts
    const autoplayTimer = startAutoplay();

    // Clean up the timer when the component unmounts or when the autoplay delay changes
    return () => {
      clearInterval(autoplayTimer);
    };
  }, [autoplayDelay]);
  return (
    <div className="testimonial">
      <div className="user-heading">
        <p className="user-title">testimonials</p>
        <h1 className="user-text">What People say about us.</h1>
      </div>
      <div className="swiper-container">
        {/* <div className="swiper-wrapper"> */}
        <Swiper
          slidesPerView={slidesPerView}
          spaceBetween={30}
          centeredSlides={true}
          className="mySwiper"
          onSlideChange={handleSlideChange}
          initialSlide={middleSlideIndex}
          ref={swiperRef}
        >
          {testimonialsData.map((testimonial, index) => (
            <SwiperSlide key={testimonial.id}>
              <div
                className={`testimonial-slide ${
                  index === activeSlide ? "active-slider" : ""
                }`}
              >
                <div className="active-slider-upper">
                  <div className="active-slider-active-icon">
                    <BiSolidQuoteAltLeft className="test-quote" />
                  </div>
                  <div className="active-slider-active-text">
                    <p className="text-slider-active">
                      {testimonial.text}
                      <br />
                      {testimonial.subtext}
                    </p>
                  </div>
                </div>
                <div className="active-slider-bottom">
                  <div className="active-slider-active-name">
                    <p className="slider-bottom-name">
                      {testimonial.author}
                      <br />
                      <span style={{ display: "flex" }}>
                        {testimonial.joinDate}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="swiper-navigation">
        <button
          // className="swiper-button-prev"
          onClick={handlePrevSlide}
        >
          {/* <FcPrevious className="testimonial-prev" /> */}
          <svg
            width="30"
            height="29"
            viewBox="0 0 30 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17.7382 23.6519L9.33821 15.2519C9.11471 15.0271 8.98926 14.7229 8.98926 14.4059C8.98926 14.0889 9.11471 13.7847 9.33821 13.5599L17.7382 5.15991L19.4302 6.85191L11.8822 14.3999L19.4302 21.9479L17.7382 23.6519Z"
              fill="#4B4C4D"
            />
          </svg>
        </button>
        <button
          // className="swiper-button-next"
          onClick={handleNextSlide}
        >
          {/* <FcNext className="testimonial-next" /> */}
          <svg
            width="29"
            height="29"
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.6725 23.6519L9.98047 21.9479L17.5285 14.3999L9.98047 6.85195L11.6725 5.14795L20.0725 13.5479C20.296 13.7728 20.4214 14.0769 20.4214 14.3939C20.4214 14.711 20.296 15.0151 20.0725 15.2399L11.6725 23.6519Z"
              fill="#4B4C4D"
            />
          </svg>
        </button>
      </div>
    </div>
    // </div>
  );
};

export default Testimonials;
