import React from "react";
import "./nearby.css";
import image from "../../../Assets/MainScreen/meetpeople.avif";
import team from "../../../Assets/team.jpeg";
import frnds from "../../../Assets/frnds.jpeg";
import frnds2 from "../../../Assets/frnds2.jpeg";
import frnds3 from "../../../Assets/frnds3.jpeg";
import frnds4 from "../../../Assets/frnd4.jpeg";
import frnds5 from "../../../Assets/frnd5.jpeg";


const Nearby = () => {
  return (
    <div>
      <div className="nearby-top">
        <div className="nearby-top-container">
          <div>
            <img src={frnds} className="nearby-top-image" />
          </div>
          <div>
            <h1 className="nearby-heading">
              Find new friends - right <br /> near you!
            </h1>
            <p className="nearby-subheading">
              Are you new to your neighborhood or just want to expand your
              circle of friends? You can easily get to know people from your
              area via Apna Konnect.
            </p>
            <a
              className="get"
              style={{ color: "blue" }}
              href="https://www.w3schools.com/css/css_padding.asp"
            >
              {/* <u> Get Started &rarr;</u> */}
            </a>
          </div>
        </div>
      </div>
      <div className="nearby-images">
        <div className="nearby-left">
          <div className="nearby-card-left">
            <div className="n-card-r-1">
              <div className="n-card-container-r-1">
                <img className="n-card-faq-page" src={frnds2} />
              </div>
              <div className="n-card-container-text">
                <div className="n-card-container-text-heading">
                  <div style={{ display: "flex" }}>
                    <div
                      className="n-card-subheading"
                      style={{ marginRight: "10px" }}
                    >
                      Explore a new way to find 
                    </div>
                    {/* <div className="n-card-subheading">1 day ago</div> */}
                  </div>
                  <p className="n-card-heading">Friends Near you</p>
                </div>
              </div>
              {/* <div className="n-card-social-icons">
                <div className="n-card-icons">
                  <div className="n-card-icon-1">
                    <p>13</p>
                    <ThumbUpOutlinedIcon />
                  </div>
                  <div className="n-card-icon-1">
                    <p>9</p>
                    <CommentOutlinedIcon />
                  </div>
                </div>
              </div> */}
            </div>
            <div className="n-card-r-1">
              <div className="n-card-container-r-1">
                <img className="n-card-faq-page" src={frnds5} />
              </div>
              <div className="n-card-container-text">
                <div className="n-card-container-text-heading">
                  <div style={{ display: "flex" }}>
                    <div
                      className="n-card-subheading"
                      style={{ marginRight: "10px" }}
                    >
                      Make Long Terms 
                    </div>
                    {/* <div className="n-card-subheading">1 day ago</div> */}
                  </div>
                  <p className="n-card-heading">Connections and Memories</p>
                </div>
              </div>
              {/* <div className="n-card-social-icons">
                <div className="n-card-icons">
                  <div className="n-card-icon-1">
                    <p>13</p>
                    <ThumbUpOutlinedIcon />
                  </div>
                  <div className="n-card-icon-1">
                    <p>9</p>
                    <CommentOutlinedIcon />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="nearby-Right">
          <div className="right-images">
            <div className="n-image-container">
              <img src={frnds3} className="n-image-1" />
            </div>
            <div className="n-image-container">
              <img src={frnds4} className="n-image-2" />
            </div>
          </div>
        </div>
      </div>
      <div className="scrollable-container">
        <div className="n-mobile-scroll">
          {/* <div>
            <img src={team} className="nearby-top-image-mobile" />
          </div> */}
          <div className="nearby-images-mobile">
            <div className="nearby-Right-mobile">
              <div className="right-images-mobile">
                <div className="n-image-container">
                  <img src={frnds3} className="n-image-1-mobile" />
                </div>
                <div className="n-image-container">
                  <img src={frnds4} className="n-image-2-mobile" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nearby;
