import React from "react";
import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar/Navbar"
import "./PrivacyPolicy.css"
const PrivatePolicy = () => {
  return (
    <>
      <div>
      <Navbar />
      <div className="rc">
        <div className="marignnar">
        <div className="rc-header">
          <h1 className="rc-h1">Privacy Policy</h1>
          
        </div>
        <div>
          <div style={{ borderBottom: "1px solid #225c7b" }}>
            <p className="rc-p">
              Resshu Connect Pvt. Ltd. (Company, we, our, us) is committed to
              your privacy. This Privacy Policy (Privacy Policy) sets out the
              usage of information provided or disclosed to the Company, which
              may be inclusive of your personal information, and also brings to
              your notice your rights as per the terms of this Privacy Policy.
              This Privacy Policy must be read in conjunction with the Terms of
              Service.
            </p>
            <p className="rc-p">
              Our Privacy Policy is in accordance with the provisions of
              Information Technology Act, 2000 and the Rules made thereunder.
            </p>
            <p className="rc-p">
              By accessing, downloading, or using the website or the allied
              mobile application, Apna Konnect (Application, website, platform)
              you agree to be governed by this Privacy Policy.
            </p>
          </div>
          <h3 className="rc-h3">Scope</h3>
          <p className="rc-p">
            {" "}
            1.1 This Privacy Policy applies to the Services, Application or any
            online application or service that refers to or links to this
            Privacy Policy. This Privacy Policy applies regardless of whether
            you have downloaded the application or accessed or used the website
            from your mobile phone or handheld device or any other computer
            resource to access our Services.
          </p>
          <p className="rc-p">
            1.2 Apna Konnect is a public, social platform. The users acknowledge
            and understand that any content (including their user handle,
            profile picture and published posts) created by the users, is
            publicly available and searchable by anyone around the world. It is
            advisable that Users do not post any sensitive personal information
            on the platform. You should be careful of what you are posting on
            the platform, as the updates will reflect in your feed and will be
            visible to other users or anyone who accesses our services,
            depending on the privacy setting specifically chosen by you for your
            account.
          </p>
          <p className="rc-p">
            1.3 You acknowledge and agree that by providing public content on
            Apna Konnect, you are authorizing and advising us to disclose that
            information on the platform and allow for wide circulation.
          </p>

          <h3 className="rc-h3">Information we collect</h3>
          <p className="rc-p">
            2.1 At the time of registration: when you choose to register on the
            platform, we seek certain identifiers which qualify as personal
            data, and some of these identifiers will have to be mandatorily
            collected, and some will have to be collected only upon your
            discretion, and consent.
          </p>
          <p className="rc-p">
            2.2 The information which you provide to us, mandatorily are as
            follows:
          </p>
          <div style={{ marginLeft: "40px" }}>
            <p className="rc-p">
              2.2.1 Mobile number and/or e-mail address: For creation of a
              unique profile, identification, authentication via OTP and any
              communications from us or on our behalf or by any third parties
              engaged by us to provide the service.
            </p>
            <p className="rc-p">
              2.2.2 Username or e-mail address or profile photo: if you sign
              up/sign in to your Apna Konnect account using a One Tap/Single
              Sign On (SSO) from any supported third party service which allows
              authentication of your identity and allows you to share personal
              information with us (such as your name or email or profile photo)
              to pre-populate sign-up form or sign-in to the Apna Konnect
              platform.
            </p>
          </div>
          <p className="rc-p">
            2.3 The information which you may choose to additionally provide
            are:
          </p>
          <div style={{ marginLeft: "40px" }}>
            <p className="rc-p">
              2.3.1 Name, for the purposes of displaying on your profile
            </p>
            <p className="rc-p">
              2.3.2 User handle preference, for the purpose of identification on
              the platform
            </p>
            <p className="rc-p">
              2.3.3 Date of Birth, for the purpose of identification and/or
              displaying on your profile
            </p>{" "}
            <p className="rc-p">
              2.3.4 Gender, for the purposes of displaying on your profile
            </p>{" "}
            <p className="rc-p">
              2.3.5 Profile Picture, for the purposes of displaying on your
              profile
            </p>{" "}
            <p className="rc-p">
              2.3.6 Location, for the purposes of customizing your experience on
              the platform
            </p>{" "}
            <p className="rc-p">
              2.3.7 Professional details, for the purposes of displaying on your
              profile
            </p>{" "}
            <p className="rc-p">
              2.3.8 Description of Self, for the purposes of displaying on your
              profile
            </p>{" "}
            <p className="rc-p">
              2.3.9 Relationship Status, for the purposes of displaying on your
              profile
            </p>{" "}
            <p className="rc-p">
              2.3.10 Banking information, for the purposes of conducting any
              transactions on the Apna Konnect platform
            </p>
          </div>
          <p className="rc-p">
            2.4 Information from your use of our services – When you use our
            services, we may collect the following information automatically:
          </p>
          <div style={{ marginLeft: "40px" }}>
            <p className="rc-p">
              2.4.1 Content of the posts (text, images, graphics, audio,
              visuals, etc.){" "}
            </p>
            <p className="rc-p">2.4.2 Users who follow you on the platform</p>
            <p className="rc-p">
              2.4.3 People who have visited your profile and vice versa
            </p>{" "}
            <p className="rc-p">
              2.4.4 Information on your browser’s user agent string and server
              logs, including your IP address, information in the cookies and
              activity on the platform{" "}
            </p>{" "}
            <p className="rc-p">
              2.4.5 URL information, time stamp, visit information, your
              browsing history on the platform
            </p>{" "}
            <p className="rc-p">
              2.4.6 Device information, such as make, model and operating system
              of the device used for browsing the platform
            </p>{" "}
            <p className="rc-p">
              2.4.7 Date of download and/or reinstall of the App
            </p>{" "}
            <p className="rc-p">
              2.4.8 Events related to your action on the platform
            </p>{" "}
            <p className="rc-p">
              2.4.9 The Unique Device Identifier on the Application
            </p>{" "}
            <p className="rc-p">
              2.4.10 Locational information as permitted by you in your mobile
              device settings. We do not collect any further information which
              is not specifically communicated to you as per this Privacy
              Policy.
            </p>
            <p className="rc-p">
              2.4.11 Banking information, for the purposes of conducting any
              transactions on the Apna Konnect platform
            </p>
          </div>
          <p className="rc-p">
            2.5 Surveys – We may collect additional information at other times,
            including but not limited to, when you provide feedback, modify your
            content or email preferences, respond to surveys, provide comments,
            or communicate with us. This information may include your name,
            e-mail id, mobile number, location, etc. and only such information
            that you may choose to specifically provide to us.
          </p>
          <p className="rc-p">
            2.6 Cookies – We use cookies and similar technologies which are
            necessary to its functioning. Some of these cookies are essential
            for us to provide you with the services on the platform. We, or our
            third-party service providers, may use cookies, mobile app analytics
            and similar technologies to track visitor activity and collect data
            on the platform. We may combine this data with other personal data
            we have collected from users.
          </p>
          <h3 className="rc-h3">4. Why we collect this information</h3>
          <p className="rc-p">
            (i) To help us identify you when you log onto the platform and when
            you register an account with us, and, to validate, authorize and map
            a specific profile with an authorized user
          </p>
          <p className="rc-p">
            (ii) To enhance the quality of the services that we provide and
            improve your experience during browsing
          </p>
          <p className="rc-p">
            (iii) For providing location-based services, as and where requested
            by you
          </p>
          <p className="rc-p">(iv) For the performance of legal obligations</p>
          <p className="rc-p">(v) To communicate with you</p>
          <p className="rc-p">
            (vi) To provide and process service requests initiated by you
          </p>
          <h3 className="rc-h3">When we share your information</h3>
          <p className="rc-p">
            4.1 We may share any information with our trusted partners or third
            parties who provide us with infrastructure support services, for
            meeting the obligations agreed between you and us. We may also share
            aggregated, non-personally-identifiable information publicly and
            with our partners, like publishers, advertisers or connected sites
            to show trends about the general use of our services. We will seek
            your consent prior to using or sharing your personal information for
            any other purpose, if so, identified at a later stage.
          </p>
          <p className="rc-p">
            4.2 We may also use your information to perform analytics and
            conduct customer research, to determine your interest, for
            identifying content that generates sales and to analyze traffic
            patterns.
          </p>
          <p className="rc-p">
            4.3 We also use your information to marker to you as per the Rules
            and Guidelines prescribed under the Information Technology Act,
            2000.
          </p>
          <p className="rc-p">
            4.4 You have the ability to allow us to share your information to
            third parties so that you can avail their services. You may disable
            or limit such sharing at any time.
          </p>

          <h3 className="rc-h3">Disclosure of information</h3>
          <p className="rc-p">
            5.1 We may also disclose your Personal Information only:
          </p>
          <div style={{ marginLeft: "40px" }}>
            <p className="rc-p">
              5.1.1 As required by law, such as to comply with a judicial order,
              executive orders, requirement by a law enforcement authority, or
              by other legal processes.
            </p>
            <p className="rc-p">
              5.1.2 When your desired products and services can only be provided
              if your personal information is disclosed.
            </p>
            <p className="rc-p">
              5.1.3 When we believe, in good faith, that disclosure is necessary
              to protect our rights, protect your safety oor the safety of
              others, or, investigate fraud or crime.{" "}
            </p>{" "}
            <p className="rc-p">
              5.1.4 If we (or our affiliates) are involved in a merger,
              acquisition, or sale of all or substantially all of its assets or
              equity. Such information shall be disaggregated to the extent
              possible and shall be subject to execution of appropriate
              non-disclosure agreements and binding privacy undertakings.
            </p>{" "}
          </div>
          <p className="rc-p">
            5.2 We may also disclose your Personal Information only:
          </p>
          <h3 className="rc-h3">User Rights on Apna Konnect</h3>
          <p className="rc-p">
            We want to ensure that you are fully empowered on the Apna Konnect
            platform and are completely aware of the rights that you are
            entitled to in the course of using our platform. You have a number
            of rights in relation to the information we hold about you.
          </p>
          <p className="rc-p">
            (i) Access – The right to access the information we hold about you,
            and to obtain details of the processing. You also have the right to
            access a list of all the third parties that have your personal
            information through us.
          </p>
          <p className="rc-p">
            (ii) Rectification – The right to seek, correct, update and modify
            the information available with us, to ensure accuracy.
          </p>
          <p className="rc-p">
            (iii) Cancellation – The right to seek to cancel or erase free of
            charge, your personal data when it is inadequate, excessive, or
            unnecessary. This shall be subject to lawful processing measures and
            any legal prescriptions.
          </p>
          <p className="rc-p">
            (iv) Objection – The right to withdraw your consent to our continued
            processing of the information, at any point of time, subject only to
            any legitimate reason for continued processing in certain
            circumstances.
          </p>
          <p className="rc-p">
            (v) Portability – The right to seek from us your personal data to be
            provided to another service provider.
          </p>
          <h3 className="rc-h3">
            How long will your personal information be stored with us?
          </h3>
          <p className="rc-p">
            7.1 In relation to other personal information, we store them for
            certain predetermined periods on the basis of (i) statutory legal
            requirements, (ii) industry guidelines, (iii) de-identified or
            pseudonymized data sets to be used in an aggregated format for
            scientific, statistical or historical purposes.
          </p>
          <p className="rc-p">
            7.2 We retain the information which we have collected for a period
            no longer than is necessary, and as may be required in law. If we
            need to retain your personal information for a longer period, we
            will inform you prior to the extension of the storage period and
            seek your explicit consent to extend the retention period. We will
            delete your information whenever you request us to do so. However,
            we may archive and/or retain some information for legal purposes.
            Any other information which is processed in an aggregated or
            non-identifiable basis.
          </p>
          <h3 className="rc-h3">Opting out</h3>
          <p className="rc-p">
            8.1 You can always opt out of our services or choose not to disclose
            information to us at any time. However, keep in mind that some
            information may be needed to register with us or to take advantage
            of some of our special features. By providing limited information,
            or by availing the opt-out provision, you may not be able to access
            full functionalities of our services and platform, and some features
            may be disabled for your access.
          </p>
          <p className="rc-p">
            8.2 We reserve the right to continue to keep a copy of any of your
            personal information if required by law. We may use any aggregated/
            anonymized data derived from your account, in a matter which shall
            not infringe upon your privacy.
          </p>
          <h3 className="rc-h3">The security of your information</h3>
          <p className="rc-p">
            9.1 Your personal data is maintained by us in electronic form. We
            shall take all necessary precautions to protect your personal data
            and implement reasonable security practices and measures including
            certain managerial, technical, operational and physical security
            control measures that are commensurate with respect to the
            information being collected and the nature of our business.
            Specifically, we will ensure that the security infrastructure put in
            place by us to safeguard your personal information against loss,
            unauthorized access, destruction, use, processing, storage
            modification or de-anonymization, will at all times be adherent to
            the best industry standards.
          </p>
          <p className="rc-p">
            9.2 We restrict access to personal information to company employees,
            contractors, and agents who need that information in order to
            process it. Anyone with this access is subject to strict contractual
            confidentiality obligations and may be disciplined or terminated if
            they fail to meet these obligations.
          </p>

          <h3 className="rc-h3">Personal data of others</h3>
          <p className="rc-p">
            In some situations, you may provide personal data of other
            individuals (family, friends, etc.) to us. If you provide us with
            such personal data, you represent and warrant that you have obtained
            their consent for their personal information to be collected, used
            and disclosed as set out in this privacy policy.
          </p>
          <h3 className="rc-h3">Changes to this privacy policy</h3>
          <p className="rc-p">
            We make periodical changes to the privacy policy. Any significant
            changes we may make to our privacy policy in the future will be
            promptly notified to users by posting the relevant terms in a
            prominent position on the platform. The new terms may be displayed
            on the platform, and you will be required to read and accept them to
            continue your use of the services.
          </p>
        </div>
      </div>
      </div>
        </div>
      <Footer />
    </>
  );
};

export default PrivatePolicy;
