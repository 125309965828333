import React, { useState } from "react";
import logo1 from "../../../Assets/shells.png";
import "./usergroup.css";
const UserGroups = () => {
  return (
    <div>
      <section className="user-groups">
        <div className="user-heading">
          <p className="user-title">User Groups</p>
          {/* <h1 className="user-text">
            Different profiles 
          </h1> */}
        </div>
        <div className="paragraph">
          <p className="user-paragraph">
            Specially curated platform dedicated for people to develop
            meaningful interactions and valuable Konnections in the real world.
          </p>
        </div>
        {/* <div className="user-main"> */}
        <div className="user-boxes">
          <div className="user-box">
            <h1 className="box1-heading">Members</h1>
            <p className="box1-text">
              For individuals looking to connect with their locality or 
              just want to be upto date on local new and updates. 
            </p>
            <div>
              <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24 4.5C13.2478 4.5 4.5 13.2478 4.5 24C4.5 34.7522 13.2478 43.5 24 43.5C34.7522 43.5 43.5 34.7522 43.5 24C43.5 13.2478 34.7522 4.5 24 4.5ZM24.1875 13.5C25.5225 13.5 26.8276 13.8959 27.9376 14.6376C29.0476 15.3793 29.9128 16.4335 30.4237 17.6669C30.9346 18.9003 31.0683 20.2575 30.8078 21.5669C30.5474 22.8762 29.9045 24.079 28.9605 25.023C28.0165 25.967 26.8137 26.6099 25.5044 26.8703C24.195 27.1308 22.8378 26.9971 21.6044 26.4862C20.371 25.9753 19.3168 25.1101 18.5751 24.0001C17.8334 22.8901 17.4375 21.585 17.4375 20.25C17.4375 18.4598 18.1487 16.7429 19.4145 15.477C20.6804 14.2112 22.3973 13.5 24.1875 13.5ZM24 40.5C21.7242 40.5009 19.4729 40.0299 17.3884 39.1165C15.3039 38.2032 13.4314 36.8675 11.8894 35.1937C12.7181 30.9019 20.1619 30 24 30C27.8381 30 35.2819 30.9019 36.1106 35.1928C34.5688 36.8669 32.6964 38.2029 30.6118 39.1164C28.5273 40.0299 26.2759 40.501 24 40.5Z"
                  fill="#21272A"
                />
              </svg>
            </div>
          </div>
          <div className="user-box">
            <h1 className="box1-heading">Premium Profile</h1>
            <p className="box1-text">
              Users who want to brand them and their services in a hyperlocal space
            </p>
            <div>
              <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24 4.5C13.2478 4.5 4.5 13.2478 4.5 24C4.5 34.7522 13.2478 43.5 24 43.5C34.7522 43.5 43.5 34.7522 43.5 24C43.5 13.2478 34.7522 4.5 24 4.5ZM30.4969 13.5159C31.8408 13.5178 33.154 13.918 34.2705 14.666C35.387 15.4139 36.2567 16.4761 36.7697 17.7183C37.2827 18.9604 37.4159 20.3267 37.1526 21.6446C36.8893 22.9624 36.2412 24.1726 35.2903 25.1222C34.3394 26.0719 33.1283 26.7183 31.8101 26.9798C30.4919 27.2413 29.1257 27.1061 27.8843 26.5914C26.6429 26.0767 25.5819 25.2055 24.8355 24.088C24.089 22.9704 23.6906 21.6567 23.6906 20.3128C23.6912 19.4196 23.8678 18.5353 24.2102 17.7103C24.5525 16.8854 25.0541 16.1359 25.6861 15.5048C26.3181 14.8736 27.0682 14.3731 27.8937 14.0319C28.7191 13.6906 29.6037 13.5153 30.4969 13.5159ZM15.9562 15.9872C17.0558 15.9887 18.1303 16.3161 19.0439 16.9281C19.9574 17.54 20.6691 18.4091 21.0888 19.4254C21.5086 20.4417 21.6177 21.5597 21.4022 22.638C21.1868 23.7163 20.6565 24.7065 19.8785 25.4835C19.1004 26.2605 18.1095 26.7894 17.0309 27.0034C15.9524 27.2173 14.8346 27.1068 13.8188 26.6856C12.8031 26.2645 11.935 25.5517 11.3242 24.6373C10.7135 23.7229 10.3875 22.648 10.3875 21.5484C10.3879 20.8176 10.5322 20.094 10.8123 19.4189C11.0924 18.7439 11.5027 18.1306 12.0198 17.6142C12.537 17.0978 13.1508 16.6883 13.8262 16.4091C14.5016 16.1299 15.2254 15.9866 15.9562 15.9872ZM8.84531 30.5184C11.1422 29.2725 14.0831 28.6491 15.9562 28.6491C17.3625 28.6491 19.1737 28.9303 20.9559 29.6053C19.7612 30.272 18.6844 31.1306 17.7684 32.1469C16.5319 33.5531 15.8222 35.2331 15.8222 36.9122V38.3184C12.7171 36.5399 10.2699 33.8056 8.84531 30.5231V30.5184ZM24 40.5C22.1017 40.5016 20.2176 40.1739 18.4313 39.5316V36.9956C18.4313 32.0653 26.4703 29.5809 30.4969 29.5809C32.6531 29.5809 35.97 30.2962 38.5791 31.7194C37.1751 34.3686 35.0763 36.5856 32.5078 38.1325C29.9394 39.6794 26.9983 40.4979 24 40.5Z"
                  fill="#21272A"
                />
              </svg>
            </div>
          </div>
          {/* </div> */}
        </div>
        {/* <div className="user-icons">
          <div className="user-icon">
            <div className="icon-logo">
              <img src={logo1} className="icon" />
            </div>
            <div className="icon-name">SHELLS</div>
          </div>
          <div className="user-icon">
            <div className="icon-logo">
              <img src={logo1} className="icon" />
            </div>
            <div className="icon-name">SHELLS</div>
          </div>{" "}
          <div className="user-icon">
            <div className="icon-logo">
              <img src={logo1} className="icon" />
            </div>
            <div className="icon-name">SHELLS</div>
          </div>{" "}
          <div className="user-icon">
            <div className="icon-logo">
              <img src={logo1} className="icon" />
            </div>
            <div className="icon-name">SHELLS</div>
          </div>{" "}
          <div className="user-icon">
            <div className="icon-logo">
              <img src={logo1} className="icon" />
            </div>
            <div className="icon-name">SHELLS</div>
          </div>
        </div> */}
      </section>
    </div>
  );
};

export default UserGroups;
