import React from 'react';
import { Link } from 'react-router-dom';
import RobotImage from '../../Assets/rocket.png'
import Navbar from "../../Common/Navbar/Navbar";
import Footer from "../../Common/Footer/Footer";

const NotFoundPage = () => {
  return (
    <>
      <Navbar />
    <div style={styles.container}>
      <img src={RobotImage} alt="404 Robot" style={styles.image} />
      <h1 style={styles.heading}><b>404 - Not Found</b></h1>
      <p style={styles.text}>Sorry, the page you are looking for is hiding from robots!</p>
      <Link to="/" style={styles.button}>Go to Home</Link>
    </div>
    <Footer/>
    </>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    padding: '20px',
    textAlign: 'center',
  },
  image: {
    maxWidth: '100%',
    height: 'auto',
    marginBottom: '20px',
  },
  heading: {
    fontSize: '32px',
    marginBottom: '20px',
  },
  text: {
    fontSize: '18px',
    lineHeight: '1.5',
  },
  button: {
    display: 'inline-block',
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    textDecoration: 'none',
    borderRadius: '5px',
    transition: 'background-color 0.3s ease',
  },
};

export default NotFoundPage;
