import React, { useState,useRef } from "react";
import useInterval from "../../../hooks/useInterval";
import "./Highlight.css";
import ReactPlayer from "react-player";
const Highlight = () => {
  const [showVideo, setShowVideo] = useState(false);

  const openVideo = () => {
    setShowVideo(true);
  }

  const closeVideo = () => {
    setShowVideo(false);
  }
  const AnimatedNumber = ({ targetNumber }) => {
    const [currentNumber, setCurrentNumber] = useState(0);
    const intervalDelay = targetNumber > 5000 ? 200 : 200;
    const increment  = targetNumber>2000 ? 500: 10
    useInterval(() => {
      if (currentNumber < targetNumber) {
        setCurrentNumber((prev) => Math.min(prev + increment, targetNumber));
      }
    }, intervalDelay); 
  
    const formatNumber = (num) => {
      if (num >= 1000) {
        return `${(num / 1000).toFixed(1)}k+`;
      }
      return num;
    };
  
    return (
      <div className="highlight-grid-name" style={{ color: "#fff" }} data-count={targetNumber}>
        {formatNumber(currentNumber)}
      </div>
    );
  };
  return (
    <>
      <section className="highlights">
        {/* <div className="gs-heading">
          <p className"gs-title">What you can find at APNA KONNECT</p>
          <h1 className="gs-text">
            Meet new people, avail offers of neighborhood markets and services ,
            join and create interest based commun Experience the real connect
            local events.
          </h1>
        </div> */}
        <div class="highlight-grid-container">
          <div className="highlight-grid-Left">
            <div className="highlight-container">
              <div className="highlight-grid-icon">
                <svg
                  width="78"
                  height="78"
                  viewBox="0 0 78 78"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M41.2548 24.2676V46.6195L50.9618 36.9125L54.3942 40.3448L38.8281 55.9109L23.262 40.3448L26.6943 36.9125L36.4013 46.6195V24.2676H13.9539C13.4711 24.2676 13.0082 24.4593 12.6669 24.8007C12.3255 25.142 12.1338 25.6049 12.1338 26.0876V70.9825C12.1338 71.4653 12.3255 71.9282 12.6669 72.2695C13.0082 72.6109 13.4711 72.8026 13.9539 72.8026H63.7023C64.185 72.8026 64.6479 72.6109 64.9892 72.2695C65.3306 71.9282 65.5223 71.4653 65.5223 70.9825V26.0876C65.5223 25.6049 65.3306 25.142 64.9892 24.8007C64.6479 24.4593 64.185 24.2676 63.7023 24.2676H41.2548Z"
                    fill="#fff"
                  />
                  <path
                    d="M41.2549 4.85352H36.4014V24.2675H41.2549V4.85352Z"
                    fill="#fff"
                  />
                </svg>
              </div>
              <AnimatedNumber targetNumber={120000} />
            </div>
            <div className="highlight-grid-text"style={{color:"#fff"}}>Downloads</div>
          </div>
          {/* <div className="highlight-grid-Middle">
            <div className="highlight-container">
              <div className="highlight-grid-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="78" height="78" fill="white" class="bi bi-person-fill" viewBox="0 0 16 16">
  <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
</svg>
              </div>
              <div className="highlight-grid-name"style={{color:"#fff"}}>18k+</div>
            </div>
            <div className="highlight-grid-text" style={{color:"#fff"}}>Users</div>
          </div> */}
          <div className="highlight-grid-Middle">
            <div className="highlight-container">
              <div className="highlight-grid-icon">
                <svg
                  width="78"
                  height="78"
                  viewBox="0 0 78 78"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23.0542 38.8281C29.0853 38.8281 33.9746 33.9388 33.9746 27.9077C33.9746 21.8765 29.0853 16.9873 23.0542 16.9873C17.023 16.9873 12.1338 21.8765 12.1338 27.9077C12.1338 33.9388 17.023 38.8281 23.0542 38.8281Z"
                    fill="#fff"
                  />
                  <path
                    d="M35.4912 44.8953C31.2202 42.7263 26.5062 41.8618 23.0541 41.8618C16.2926 41.8618 2.42676 46.0085 2.42676 54.2989V60.6691H25.1776V58.2318C25.1776 55.35 26.3909 52.4607 28.5143 50.0521C30.2085 48.1289 32.5807 46.3437 35.4912 44.8953Z"
                    fill="#fff"
                  />
                  <path
                    d="M51.569 43.6816C43.6715 43.6816 27.9082 48.5594 27.9082 58.2422V65.5224H75.2299V58.2422C75.2299 48.5594 59.4666 43.6816 51.569 43.6816Z"
                    fill="#fff"
                  />
                  <path
                    d="M51.5688 38.8281C58.9402 38.8281 64.9159 32.8523 64.9159 25.4809C64.9159 18.1095 58.9402 12.1338 51.5688 12.1338C44.1974 12.1338 38.2217 18.1095 38.2217 25.4809C38.2217 32.8523 44.1974 38.8281 51.5688 38.8281Z"
                    fill="#fff"
                  />
                </svg>
              </div>
              <AnimatedNumber targetNumber={2000}/> 
            </div>
            <div className="highlight-grid-text"style={{color:"#fff"}}>Communities</div>
          </div>
          <div className="highlight-grid-Middle">
            <div className="highlight-container">
              <div className="highlight-grid-icon">
                <svg
                  width="78"
                  height="78"
                  viewBox="0 0 78 78"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_208_34845)">
                    <path
                      d="M38.8278 50.9614C38.0361 50.9614 37.2504 50.925 36.4754 50.8567C32.4763 50.4993 28.6092 49.2451 25.1616 47.1872C21.7141 45.1292 18.7748 42.3206 16.5623 38.9702L3.0332 63.0952H20.4755L29.2725 77.6557L41.7899 50.7961C40.8063 50.9056 39.8175 50.9608 38.8278 50.9614Z"
                      fill="#fff"
                    />
                    <path
                      d="M61.1249 38.9399C57.9208 43.7924 53.2271 47.4726 47.7504 49.4265L41.5137 63.0194L48.3844 77.6557L57.1814 63.0952H74.6237L61.1249 38.9399Z"
                      fill="#fff"
                    />
                    <path
                      d="M38.8281 31.5473C42.8489 31.5473 46.1084 28.2878 46.1084 24.2671C46.1084 20.2463 42.8489 16.9868 38.8281 16.9868C34.8073 16.9868 31.5479 20.2463 31.5479 24.2671C31.5479 28.2878 34.8073 31.5473 38.8281 31.5473Z"
                      fill="#fff"
                    />
                    <path
                      d="M38.8281 2.42676C26.7853 2.42676 16.9873 12.2248 16.9873 24.2675C16.9873 36.3103 26.7853 46.1083 38.8281 46.1083C50.8708 46.1083 60.6688 36.3103 60.6688 24.2675C60.6688 12.2248 50.8708 2.42676 38.8281 2.42676ZM38.8281 36.4013C36.4282 36.4013 34.0823 35.6896 32.0869 34.3564C30.0915 33.0231 28.5363 31.1281 27.6179 28.9109C26.6996 26.6938 26.4593 24.2541 26.9275 21.9003C27.3956 19.5466 28.5513 17.3846 30.2482 15.6877C31.9451 13.9907 34.1072 12.8351 36.4609 12.3669C38.8146 11.8987 41.2543 12.139 43.4715 13.0574C45.6886 13.9758 47.5836 15.531 48.9169 17.5264C50.2502 19.5218 50.9618 21.8677 50.9618 24.2675C50.9582 27.4845 49.6787 30.5687 47.4039 32.8434C45.1292 35.1181 42.045 36.3977 38.8281 36.4013Z"
                      fill="#fff"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_208_34845">
                      <rect width="77.6561" height="77.6561" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <AnimatedNumber targetNumber={200}/>
            </div>
            <div className="highlight-grid-text" style={{color:'#fff'}}>Events</div>
          </div>
        
          <div className="highlight-grid-Right">
            <div className="highlight-container">
              <div className="highlight-grid-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="78" height="78" fill="white" class="bi bi-cart-plus-fill" viewBox="0 0 16 16">
  <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0m7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0M9 5.5V7h1.5a.5.5 0 0 1 0 1H9v1.5a.5.5 0 0 1-1 0V8H6.5a.5.5 0 0 1 0-1H8V5.5a.5.5 0 0 1 1 0"/>
</svg>
              </div>
              <AnimatedNumber targetNumber={5000}/>
            </div>
            <div className="highlight-grid-text">Advertisers</div>
          </div>
          
          <div className="highlight-grid-Right">
            <div className="highlight-container">
              <div className="highlight-grid-icon">
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 78 78"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M38.828 2.42676L2.42676 53.3885L38.828 75.2293L75.2293 53.3885L38.828 2.42676ZM35.7946 17.1116V66.3337L11.1828 51.5685L35.7946 17.1116Z"
                    fill="#fff"
                  />
                </svg>
              </div>
              <AnimatedNumber targetNumber={300}/>
            </div>
            <div className="highlight-grid-text">Comm. Pillars</div>
          </div>
        </div>
      </section>
      <div className="Acc-Container" style={{border:"#00AC73"}}>
        {/* <button className="btn-show-less Acc-button" style={{background:"#00AC73", color:"#fff"}} onClick={openVideo}>Watch Tutorials</button> */}
        {showVideo && (
        <div className="video-modal">
          <div className="video-modal-content">
            <ReactPlayer
              url="https://youtu.be/k41nyQPT8hA?si=SdyVTe0wLoEilfFN"
              controls
              width="100%"
              height="100%"
            />
            <button className="btn btn-outline-secondary close-button" onClick={closeVideo}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#00ac73" className="bi bi-x" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
              </svg>
            </button>
          </div>
        </div>
      )}
      </div>
    </>
  );
};

export default Highlight;