import React from "react";
import "./Install.css";
import Navbar from "../../Common/Navbar/Navbar";
import Footer from "../../Common/Footer/Footer";
import mobile from "../../Assets/Image.png";
import apple from "../../Assets/Videos/apple.png";
import android from "../../Assets/Videos/android.png";
import download from "../../Assets/downloadimage.jpg"

const Install = () => {
  return (
    <>
      <Navbar />
      <div className="installApp">
        <div className="phone-Screen-container">
          <div className="phone-border">
            <img src={download} className="phone-image" alt="Phone Screen" />
          </div>
        </div>
        <div className="ia-text-container">
          <div className="ia-text-container-heading">Download The App</div>
          <div className="ia-text-container-body">
          India’s first hyper-local community app.
          </div>
          <div className="ia-text-container-buttons">
            <div className="ia-apple-button">
              <a href="https://apps.apple.com/in/app/apna-konnect/id1673410045">
                <img
                  alt="Download on the App Store"
                  className="ia-apple-icon"
                  src="https://1000logos.net/wp-content/uploads/2020/08/apple-app-store-logo.jpg"
                />
              </a>
            </div>
            <div className="ia-android-button">
              <a href="https://play.google.com/store/apps/details?id=com.apnakonnect">
                <img
                  alt="Get it on Google Play"
                  className="ia-playStore-icon"
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/2560px-Google_Play_Store_badge_EN.svg.png"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="ia-download">
        <div className="ia-download-container">
          <div className="ia-download-container-header">
            Connect with your local Communities.
          </div>
          <div className="ia-download-container-images">
            <div className="ia-download-container-apple">
              <img src={apple} className="apple-image" alt="Apple Store" />
              <label className="apple-title-container">
                <span className="apple-title">iOS</span>
              </label>
            </div>
            <div className="ia-download-container-android">
              <img src={android} className="android-image" alt="Google Play" />
              <label className="android-title-container">
                <span className="android-title">Android</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Install;
