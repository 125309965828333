import React from "react";
import "./Features.css";
const Features = () => {
  return (
    <div className="Features">
      <div className="Features-heading">Features</div>
      <div className="Features-subHeading">
        Few good reasons why you should use APAN KONNECT to boost your business.
      </div>
    </div>
  );
};

export default Features;
