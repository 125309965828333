import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Landing from "./layouts/Landing";
import ContactUs from "./layouts/ContactUs/Contactus";
import AboutUs from "./layouts/AboutUs/aboutUs";
import Install from "./layouts/InstallApp/Install";
import FAQS from "./layouts/FAQS/FAQS";
import Team from "./layouts/Team/Team";
import Premium from "./layouts/Premium/Premium";
import Pricing from "./layouts/Pricing/Pricing";
import BusinessOrganization from "./layouts/Forms/BusinessOrganization";
import Campus from "./layouts/Forms/Campus";
import NonprofitOrganization from "./layouts/Forms/nonprofitOrganization";
import RCPolicy from "./Common/QuickLinks/RCPolicy/RCPolicy";
import PrivatePolicy from "./Common/QuickLinks/PrivatePolicy/PrivatePolicy";
import TermOfService from "./Common/QuickLinks/TermsofService/TermofService";
import NotFoundPage from "./layouts/PageNotFound/PageNotFound";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Navigate to="/web/home" replace />} />
      <Route path="/web/home" element={<Landing />}></Route>
      <Route path="/web/contact" element={<ContactUs />}></Route>
      <Route path="/web/aboutus" element={<AboutUs />}></Route>
      <Route path="/web/installApp" element={<Install />}></Route>
      <Route path="/web/faqs" element={<FAQS />}></Route>
      <Route path="/web/teamdetails" element={<Team />}></Route>
      <Route path="/web/business-profile" element={<Premium />}></Route>
      <Route path="/web/pricing" element={<Pricing />}></Route>
      <Route
        path="/web/business-organization"
        element={<BusinessOrganization />}
      ></Route>
      <Route path="/web/campus-ambassador" element={<Campus />}></Route>
      <Route
        path="/web/non-profit-organization"
        element={<NonprofitOrganization />}
      ></Route>
      //Quick Links
      <Route path="/web/refund-policy" element={<RCPolicy />}></Route>
      <Route path="/web/policy" element={<PrivatePolicy />}></Route>
      <Route path="/web/terms" element={<TermOfService />}></Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  </BrowserRouter>
);
