import React from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import "./Cts.css";
const Cta = () => {
  return (
    <div className="CTA-section">
      <div className="CTA-visual">
        <div className="Ellipse">
          <div className="Ellipse-icon">
            <svg
              width="189"
              height="189"
              viewBox="0 0 189 189"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M173.224 94.0278C173.224 137.151 138.057 172.252 94.5 172.252C50.943 172.252 15.7761 137.151 15.7761 94.0278C15.7761 50.9048 50.943 15.8039 94.5 15.8039C138.057 15.8039 173.224 50.9048 173.224 94.0278Z"
                stroke="#009379"
                stroke-width="31.5522"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="CTA-Header">
        <div className="CTA-Header-Container">
          <div className="CTA-Heading">Connect with local audiance now.</div>
          <div className="CTA-SubHeading">
            Best way to reach out to your local customers and create a local brand.
          </div>
        </div>
        <a href="/installApp">
        <button className="p_CTA-button">
          <div className="p_button-text">Download Now</div>
          <div className="p_button-icon-2-container">
            <AiOutlineArrowRight className="p_button-icon" />
          </div>
        </button>
        </a>
      </div>
    </div>
  );
};

export default Cta;
