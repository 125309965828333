import React from "react";
import TeamFemale from "../../Assets/team-image-1.png";
import TeamMale from "../../Assets/team-image-2.png";
import Navbar from "../../Common/Navbar/Navbar";
import Footer from "../../Common/Footer/Footer";
import Samir from "../../Assets/Aboutus/Samir.png";
import Reena from "../../Assets/Aboutus/Reena.png";
import Shubh from "../../Assets/Aboutus/shubh.png";
import Abhay from "../../Assets/Aboutus/abhay.png";
import Ashu from "./../../Assets/Aboutus/asutosh.jpg";
import sushuu from "./../../Assets/Aboutus/sushwar.jpg";
import aayush from "./../../Assets/Aboutus/aayush.jpg";
import anand from "./../../Assets/Aboutus/anand.png"

import "./Team.css";
const Team = () => {
  return (
    <>
      <Navbar />
      <div className="team">
        <div className="team-heading-container">
          <div className="team-heading">
            {/* <p className="heading-text">OUR TEAM</p> */}
          </div>
          <div className="team-subheading">
            <p className="subheading-text">Meet the Apna Konnect Team</p>
          </div>
          <div className="team-para">
            <p className="para-text">
            Meet our dedicated team of professionals committed to connecting communities and digitizing localities.
            Our diverse group of experts brings together a wealth of experience in technology, community engagement and finance.
            </p>
          </div>
        </div>
        <div className="team-cards">
          <div className="cards-container">
            <div className="cards-container-row-1">
              <div className="card-r-1">
                <div className="card-container-r-1">
                  <img className="card-faq-page" src={Samir} />
                </div>
                <div className="card-container-text">
                  <div className="card-container-text-heading">
                    <p className="card-heading">Samir Pachauri</p>
                    <p className="card-subheading">Founder & CEO - Apnakonnect</p>
                    {/* <p className="card-para">
                      There are many variations of passages of Lorem Ipsum
                      available
                    </p> */}
                  </div>
                </div>
                <div className="card-social-icons">
                  <div className="card-icons">
                    <div className="card-icon-1">
                      <a href="https://www.linkedin.com/in/samir-pachauri-bb899a17/">

                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
  <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"/>
</svg>
                      </a>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div className="card-r-1">
                <div className="card-container-r-1">
                  <img className="card-faq-page" src={Reena} />
                </div>
                <div className="card-container-text">
                  <div className="card-container-text-heading">
                    <p className="card-heading">Reena Pachauri</p>
                    <p className="card-subheading">Co-Founder</p>
                    {/* <p className="card-para">
                      There are many variations of passages of Lorem Ipsum
                      available
                    </p> */}
                  </div>
                </div>
                {/* <div className="card-social-icons">
                  <div className="card-icons">
                  <div className="card-icon-1">
                    <a href="">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
  <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"/>
</svg>
                    </a>
                    </div>
                   
                  </div>
                </div> */}
              </div>
              <div className="card-r-1">
                <div className="card-container-r-1">
                  <img className="card-faq-page" src={Shubh} />
                </div>
                <div className="card-container-text">
                  <div className="card-container-text-heading">
                    <p className="card-heading">Shubh Pachauri</p>
                    <p className="card-subheading">Co-Founder</p>
                    {/* <p className="card-para">
                      There are many variations of passages of Lorem Ipsum
                      available
                    </p> */}
                  </div>
                </div>
                <div className="card-social-icons">
                  <div className="card-icons">
                      <div className="card-icon-1">
                        <a href="https://www.linkedin.com/in/shubhpachauri/">

                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
  <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"/>
</svg>
                        </a>
                    </div>
                    
                  </div>
                </div>
              </div>
               <div className="card-r-1">
                <div className="card-container-r-1">
                  <img className="card-faq-page" src={Abhay} />
                </div>
                <div className="card-container-text">
                  <div className="card-container-text-heading">
                    <p className="card-heading">Abhay Sharma</p>
                    <p className="card-subheading">Independent Director</p>
                    {/* <p className="card-para">
                      There are many variations of passages of Lorem Ipsum
                      available
                    </p> */}
                  </div>
                </div>
                <div className="card-social-icons">
                  <div className="card-icons">
                  <div className="card-icon-1">
                    <a href="https://www.linkedin.com/in/abhay-sharma-pmp®-065b0732/">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
  <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"/>
</svg>
                    </a>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div className="card-r-1">
                <div className="card-container-r-1">
                  <img className="card-faq-page" src={anand} />
                </div>
                <div className="card-container-text">
                  <div className="card-container-text-heading">
                    <p className="card-heading">Anand Nigam</p>
                    <p className="card-subheading">Mentor</p>
                    {/* <p className="card-para">
                      There are many variations of passages of Lorem Ipsum
                      available
                    </p> */}
                  </div>
                </div>
                <div className="card-social-icons">
                  <div className="card-icons">
                  <div className="card-icon-1">
                    <a href="https://www.linkedin.com/in/anand-nigam-6011574?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app">

                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
  <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"/>
</svg>
                    </a>
                    </div>
                    
                  </div>
                </div>
              </div>
             
            </div>
            <div className="cards-container-row-1">
              <div className="card-r-1">
                <div className="card-container-r-1">
                  <img className="card-faq-page" src={Ashu} />
                </div>
                <div className="card-container-text">
                  <div className="card-container-text-heading">
                    <p className="card-heading">Ashutosh pardhan</p>
                    <p className="card-subheading">SDE - Backend</p>
                    {/* <p className="card-para">
                      There are many variations of passages of Lorem Ipsum
                      available
                    </p> */}
                  </div>
                </div>
                <div className="card-social-icons">
                  <div className="card-icons">
                  <div className="card-icon-1">
                    <a href="https://www.linkedin.com/in/aashutosh-pradhan-832461247/">

                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
  <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"/>
</svg>
                    </a>
                    </div>
                    
                  </div>
                </div>
              </div>{" "}
              <div className="card-r-1">
                <div className="card-container-r-1">
                  <img className="card-faq-page" src={sushuu} />
                </div>
                <div className="card-container-text">
                  <div className="card-container-text-heading">
                    <p className="card-heading">Naga Sushwar</p>
                    <p className="card-subheading">SDE - Full Stack</p>
                    {/* <p className="card-para">
                      There are many variations of passages of Lorem Ipsum
                      available
                    </p> */}
                  </div>
                </div>
                <div className="card-social-icons">
                  <div className="card-icons">
                  <div className="card-icon-1">
                    <a href="https://www.linkedin.com/in/naga-sushwar-467982220?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app">

                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
  <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"/>
</svg>
                    </a>
                    </div>
                   
                  </div>
                </div>
              </div>
              <div className="card-r-1">
                <div className="card-container-r-1">
                  <img className="card-faq-page" src={aayush} />
                </div>
                <div className="card-container-text">
                  <div className="card-container-text-heading">
                    <p className="card-heading">Aayush Pandey</p>
                    <p className="card-subheading">SDE - Frontend</p>
                    {/* <p className="card-para">
                      There are many variations of passages of Lorem Ipsum
                      available
                    </p> */}
                  </div>
                </div>
                <div className="card-social-icons">
                  <div className="card-icons">
                  <div className="card-icon-1">
                    <a href="https://www.linkedin.com/in/aayush-pandey-a8b65b203?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app">

                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
  <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"/>
</svg>
                    </a>
                    </div>
                   
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Team;
