import React from "react";
import "./Hero.css";
import { BsRocketTakeoff } from "react-icons/bs";
import { AiOutlineArrowRight } from "react-icons/ai";
const Hero = () => {
  return (
    <div className="p_hero">
      <div className="p_hero-header">
        <h1 className="p-header-heading">Unlock the power of local connections</h1>
        <h1 className="p-header-subheading">
        Enhance your community experience with APNA KONNECT's Premium Profile! 
        Discover a world of hyperlocal networking designed to connect you with your neighbors, 
        share your passions, and engage with your community like never before. 
        From exclusive local events and personalized recommendations to premium support and advanced features, 
        our Premium Profile ensures you get the most out of your local social interactions. 
        Join today and become a pillar of your community!
        </h1>
      </div>
      <div className="p_hero_CTA">
        <div className="p_CTA-container">
          <button className="p_CTA-button">
            <div className="p_button-icon-container">
              <a href="/installApp">
              <BsRocketTakeoff className="p_button-icon" />
              </a>
            </div>
            {/* <div className="p_button-text">Get Started</div> */}
            <div className="p_button-icon-2-container">
              <AiOutlineArrowRight className="p_button-icon" />
            </div>
          </button>
          <a href="/aboutus">
          <button className="p_CTA-button-2">
            <div className="p_button-text-2">How it works</div>
          </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Hero;
