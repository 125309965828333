import  { useState, useRef, useEffect } from "react";
import "./FAQS.css";
import Navbar from "../../Common/Navbar/Navbar";
import Footer from "../../Common/Footer/Footer";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';

const FAQS = () => {
  const [openedCollapse, setOpenedCollapse] = useState(null);
  const [showAll, setShowAll] = useState(false);
  const videoRefs = useRef([]);

  useEffect(() => {
    // Pause all videos initially
    videoRefs.current.forEach((video) => {
      if (video) video.pause();
    });
  }, []);

  const handleCollapseClick = (index) => {
    if (openedCollapse === index) {
      setOpenedCollapse(null);
      if (videoRefs.current[index]) videoRefs.current[index].pause();
    } else {
      setOpenedCollapse(index);
      videoRefs.current.forEach((video, i) => {
        if (video) {
          if (i === index) {
            video.play();
          } else {
            video.pause();
          }
        }
      });
    }
  };

  const handleLoadMoreClick = () => {
    setShowAll(true);
  };

  const handleShowLessClick = () => {
    setShowAll(false);
    setOpenedCollapse(null);
    videoRefs.current.forEach((video) => {
      if (video) video.pause();
    });
  };

  const accordionData = [
    {
      title: "What is APNA KONNECT?",
      text: "We are a hyper-local community app working on bringing local connection back in the digital age.",
      videoUrl: "https://www.apnakonnect.com/media/introduction.mp4",
    },
    {
      title: "What is Mission and Vision of APNA KONNECT?",
      text: "We are a hyper-local community app working on real world local connections.",
      videoUrl: "https://www.apnakonnect.com/media/missionAndVision.mp4",
    },
    {
      title: "How to create public community?",
      text: "This is how you can create public local communities on APNA KONNECT.",
      videoUrl: "https://apnakonnect.com/media/createpubliccommunity.mp4",
    },
    {
      title: "How to create private community?",
      text: "This is how you can create private local communities on APNA KONNECT.",
      videoUrl: "https://apnakonnect.com/media/privatecommunity.mp4",
    },
    {
      title: "How to become community pillar?",
      text: "Community pillars are the people who want to create a change in their locality.",
      videoUrl: "https://apnakonnect.com/media/becomecommunitypillaroflocality.mp4",
    },
    {
      title: "How to invite friends and family?",
      text: "This is how you can invite people on APNA KONNECT.",
      videoUrl: "https://apnakonnect.com/media/invitesKonnects.mp4",
    },
    {
      title: "Add members to your community?",
      text: "This is how you can add members to your community.",
      videoUrl: "https://apnakonnect.com/media/growcommunity.mp4",
    },
    {
      title: "How to engage your community member?",
      text: "This video demonstrates how to effectively engage your community members.",
      videoUrl: "https://apnakonnect.com/media/engagecommunitymember.mp4",
    },
    {
      title: "How to make events/activities?",
      text: "Learn how to create engaging events and activities for your community.",
      videoUrl: "https://www.apnakonnect.com/media/apnakonnectEvent.mp4",
    },
    {
      title: "How to create business profile?",
      text: "This video guides you through the process of creating a business profile on APNA KONNECT.",
      videoUrl: "https://apnakonnect.com/public/assets/img/supportVideo1.mp4",
    },
    {
      title: "How to create ads on APNA KONNECT?",
      text: "Discover how to create effective ads to promote your business or community on APNA KONNECT.",
      videoUrl: "https://apnakonnect.com/public/assets/img/supportVideo2.mp4",
    },
  ];

  const visibleData = showAll ? accordionData : accordionData.slice(0, 4);

  return (
    <>
      <Navbar />
      <div className="faqs">
        <div className="boxxeey">
          <div className="heading"> Frequently Asked Questions (FAQ's)</div>
          <div style={{ display: 'flex', flexDirection: 'column', marginTop:"10%"}}>
            {visibleData.map((data, index) => (
              <Accordion
                key={index}
                expanded={openedCollapse === index}
                onChange={() => handleCollapseClick(index)}
              >
                <AccordionSummary>
                  <Typography>
                    {data.title}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ backgroundColor: openedCollapse === index ? 'lightgreen' : 'inherit', width: '100%', }}>
                    <Typography>{data.text}</Typography>
                    <video
                      ref={(el) => (videoRefs.current[index] = el)}
                      controls
                      style={{ width: '100%', height:'80%' }}
                    >
                      <source src={data.videoUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
      </div>
      <div className="faq-accordionData">
        <div style={{display:"flex", justifyContent:"center"}}>
          {showAll ? (
            <button className="btn-show-less Acc-button" onClick={handleShowLessClick} style={{background:"#00AC73", color:"#fff"}}>Show Less</button>
          ) : (
            <button className="btn-show-less Acc-button" onClick={handleLoadMoreClick} style={{background:"#00AC73", color:"#fff"}}>Show More</button>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FAQS;
