import React from "react";
import HeroImage from "../../../Assets/apna.png";
import HomeImage from "../../../Assets/homepagelogo.png"


import "./Hero.css";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import { useNavigate } from "react-router-dom";
const Hero = () => {
  const navigate = useNavigate();
  return (
    <>
      {" "}
      <div className="hero-background"></div>
      <div className="Hero">
        <div className="Hero-Container">
       
          <div className="Hero-Left">
            <div className="Left-Heading">
              Welcome to your
              <br />
              <span className="hero-span">Neighborhood...</span>
            </div>
            <div className="Left-text">
              At APNA KONNECT you make communities & meet people in your
              neighborhood.
            </div>
            <div className="Left-buttons">
              <div className="Left-button-border">
                <button className="btn-show-less Acc-button" onClick={()=>navigate("/web/installApp")} style={{background:"#00AC73", color:"#fff"}}>Get Konnect
                <div>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.586 10.6569L11.636 6.70692C11.4538 6.51832 11.353 6.26571 11.3553 6.00352C11.3576 5.74132 11.4628 5.49051 11.6482 5.3051C11.8336 5.11969 12.0844 5.01452 12.3466 5.01224C12.6088 5.00997 12.8614 5.11076 13.05 5.29292L18.707 10.9499C18.8002 11.0426 18.8741 11.1527 18.9246 11.2741C18.9751 11.3954 19.001 11.5255 19.001 11.6569C19.001 11.7883 18.9751 11.9184 18.9246 12.0398C18.8741 12.1611 18.8002 12.2713 18.707 12.3639L13.05 18.0209C12.9578 18.1164 12.8474 18.1926 12.7254 18.245C12.6034 18.2974 12.4722 18.325 12.3394 18.3262C12.2066 18.3273 12.0749 18.302 11.952 18.2517C11.8291 18.2015 11.7175 18.1272 11.6236 18.0333C11.5297 17.9394 11.4555 17.8278 11.4052 17.7049C11.3549 17.582 11.3296 17.4503 11.3307 17.3175C11.3319 17.1847 11.3595 17.0535 11.4119 16.9315C11.4643 16.8095 11.5405 16.6992 11.636 16.6069L15.586 12.6569H6C5.73478 12.6569 5.48043 12.5516 5.29289 12.364C5.10536 12.1765 5 11.9221 5 11.6569C5 11.3917 5.10536 11.1373 5.29289 10.9498C5.48043 10.7623 5.73478 10.6569 6 10.6569H15.586Z"
                      fill="white"
                    />
                  </svg>
                </div>
                </button>  
              </div>
              <div className="Left-button-border">
                <button className="btn-show-less Acc-button" onClick={()=>navigate("/web/faqs")} style={{background:"#00AC73", color:"#fff"}}>Why Us?</button>
              </div>
            </div>
            {/* <div className="Hero-postal">
              <div className="hp-container">
                <p className="hp-text">Discover your neighborhood :</p>
                <form className="hp-foam">
                  <div className="hp-text-container">
                    <input
                      type="text"
                      className="hp-input"
                      placeholder="Enter your Pincode..."
                    />
                    <div className="hp-button-container">
                      <button className="hp-button">
                        <ArrowForwardOutlinedIcon />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div> */}
          </div>
          <div className="Hero-Right">
            <img src={HomeImage} className="Hero-Right-Image" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
