import React from "react";
import Hero from "./Hero/Hero";
import Video from "./Video/Video";
import Cta from "./Highlight_CTA/Cta";
import Features from "./Features/Features";
import Frame from "./Frames/Frame";
import Testimonials from "./Testimonials/Testimonials";
import Stories from "./Stories/Stories";
import PHeading from "./premiumHeading/Pheading";
import Navbar from "../../Common/Navbar/Navbar";
import Footer from "../../Common/Footer/Footer";

const Premium = () => {
  const url = "https://www.youtube.com/watch?v=MqNvLnhkbkI";
  return (
    <div>
      <Navbar />
      <PHeading />
      <Hero />
      <Video url={url} />
      <Cta />
      <Features />
      <Frame />
      <Testimonials />
      {/* <Stories /> */}
      <Footer />
    </div>
  );
};

export default Premium;
