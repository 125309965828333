import React, { useState } from "react";
import "./foam.css";
import Accordion from "../MainScreen/Accordion/Accordion";
import Footer from "../../Common/Footer/Footer";
import Navbar from "../../Common/Navbar/Navbar";
import { ToastContainer, toast } from "react-toastify";
const Campus = () => {
  const [state, setState] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [clgName, setClgName] = useState("");
  const [area, setArea] = useState("");
  const [pincode, setPincode] = useState("");
  const [country, setCountry] = useState("");
  const [yourRole, setYourRole] = useState("");
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        `http://192.168.1.3:3000/admin/campusambassdor/createcampusAmbassdor`,
        {
          method: "POST",
          headers: {
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0ODJlNDdhYThiOWRjODhhYzU4NTRkZiIsImlhdCI6MTY4NjMwMDEyMiwiZXhwIjoxNjk0MDc2MTIyfQ.gRGPjduj2EdDkviIqvcth3FSPkIsCbHkZUFal9a7d5A",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            first_name: fname,
            last_name: lname,
            email: email,
            phonenumber: phone,
            clgname: clgName,
            area: area,
            pincode: pincode,
            state: state,
            country: country,
            email: email,
            yourRole: yourRole,
          }),
        }
      );
      const res = await response.json();
      console.log(res);
      if (response.ok) {
        // navigate("/non-profit-organization");
        setTimeout(function () {
          window.location.reload();
        }, 1000);
        // Successful registration logic
        toast.success("Registration Successful", {
          position: "top-right",
          autoClose: 3000, // 3 seconds
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        // Handle registration error
        toast.error("Registration failed.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.error("Registration failed.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast.error("An error occurred.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div>
      <Navbar />
      <div class="formbold-main-wrapper">
        <div class="formbold-form-wrapper">
          {/* <!-- <img src="your-image-url-here.jpg"> --> */}
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <form onSubmit={handleSubmit}>
            <div class="formbold-form-title">
              <h2 class="">Register for Campus Ambassador</h2>
            </div>

            <div class="formbold-input-flex">
              <div>
                <label for="firstname" class="formbold-form-label">
                  First name
                </label>
                <input
                  type="text"
                  name="firstname"
                  id="first_name"
                  class="formbold-form-input"
                  value={fname}
                  onChange={(e) => setFname(e.target.value)}
                />
              </div>
              <div>
                <label for="lastname" class="formbold-form-label">
                  {" "}
                  Last name{" "}
                </label>
                <input
                  type="text"
                  name="lastname"
                  id="last_name"
                  class="formbold-form-input"
                  value={lname}
                  onChange={(e) => setLname(e.target.value)}
                />
              </div>
            </div>

            <div class="formbold-input-flex">
              <div>
                <label for="email" class="formbold-form-label">
                  {" "}
                  Email{" "}
                </label>
                <input
                  type="email"
                  name="email"
                  id="Email"
                  class="formbold-form-input"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <label for="phone" class="formbold-form-label">
                  {" "}
                  Phone number{" "}
                </label>
                <input
                  type="text"
                  name="phone"
                  id="phonenumber"
                  class="formbold-form-input"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>

            <div class="formbold-mb-3">
              <label for="ngo_name" class="formbold-form-label">
                College Name
              </label>
              <input
                type="text"
                name="clgname"
                id="clgname"
                class="formbold-form-input"
                value={clgName}
                onChange={(e) => setClgName(e.target.value)}
              />
            </div>

            <div class="formbold-input-flex">
              <div>
                <label for="state" class="formbold-form-label">
                  {" "}
                  State/Prvince{" "}
                </label>
                <input
                  type="text"
                  name="state"
                  id="state"
                  class="formbold-form-input"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                />
              </div>
              <div>
                <label for="country" class="formbold-form-label">
                  {" "}
                  Country{" "}
                </label>
                <input
                  type="text"
                  name="country"
                  id="country"
                  class="formbold-form-input"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                />
              </div>
            </div>

            <div class="formbold-input-flex">
              <div>
                <label for="post" class="formbold-form-label">
                  {" "}
                  Post/Zip code{" "}
                </label>
                <input
                  type="text"
                  name="post"
                  id="post"
                  class="formbold-form-input"
                  value={pincode}
                  onChange={(e) => setPincode(e.target.value)}
                />
              </div>
              <div>
                <label for="area" class="formbold-form-label">
                  {" "}
                  Area Code{" "}
                </label>
                <input
                  type="text"
                  name="area"
                  id="area"
                  class="formbold-form-input"
                  value={area}
                  onChange={(e) => setArea(e.target.value)}
                />
              </div>
            </div>

            <div className="formbold-mb-3">
              <label htmlFor="yourRole" className="formbold-form-label">
                Your Role
              </label>
              <select
                className="campus-select"
                name="yourRole"
                id="yourRole"
                value={yourRole}
                onChange={(e) => setYourRole(e.target.value)}
              >
                <option value="Digital-savvy">Digital-savvy</option>
                <option value="Outgoing personality">
                  Outgoing personality
                </option>
                <option value="Strategic administrator">
                  Strategic administrator
                </option>
                <option value="Convincing influencer">
                  Convincing influencer
                </option>
              </select>
            </div>

            <button class="formbold-btn">Register Now</button>
          </form>
        </div>
      </div>
      <Accordion />
      <Footer />
    </div>
  );
};

export default Campus;
