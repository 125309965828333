import React, { useState } from "react";
import "./Accordition.css";

const Accordion = () => {
  const [openedCollapse, setOpenedCollapse] = useState(null);
  const [showAll, setShowAll] = useState(false);

  const handleCollapseClick = (index) => {
    setOpenedCollapse((prev) => (prev === index ? null : index));
  };

  const handleLoadMoreClick = () => {
    setShowAll(true);
  };

  const handleShowLessClick = () => {
    setShowAll(false);
    setOpenedCollapse(null); // Close any opened accordion item when showing less
  };

  const accordionData = [
    {
      title: "Who should use the app?",
      content: "Answer",
    },
    {
      title: "What is included with my subscription?",
      content: "Answer",
    },
    {
      title: "How do I get paid?",
      content: "Answer",
    },
    {
      title: "Is my personal information safe?",
      content: "Answer",
    },
    {
      title: "How can we get in touch?",
      content: "Answer",
    },
    // Add more data as needed
  ];

  const visibleData = showAll ? accordionData : accordionData.slice(0, 4);

  return (
    <>
      <div className="gs-heading">
        <p className="gs-text">Any questions about Apnakonnect?</p>
        {/* <h1 className="gs-text">Any questions about Apnakonnect?</h1> */}
      </div>
      <div className="mt-10 p-10">
        {visibleData.map((data, index) => (
          <div
            key={index}
            className={`collapse collapse-plus border-inherit bg-slate-50 mb-3 radio2${
              openedCollapse === index ? "active" : ""
            }`}
            onClick={() => handleCollapseClick(index)}
          >
            <input
              type="radio"
              name="my-accordion-3"
              checked={openedCollapse === index}
              readOnly
            />
            <div className="collapse-title text-xl font-medium">
              {data.title}
            </div>
            <div className="collapse-content">
              <p>{data.content}</p>
            </div>
          </div>
        ))}
        {showAll ? (
          <div className="Acc-Container">
            <button
              className="btn-show-less Acc-button"
              onClick={handleShowLessClick}
            >
              Show Less
            </button>
          </div>
        ) : (
          <div className="Acc-Container">
            <button
              className="btn-load-more Acc-button Acc-Container"
              onClick={handleLoadMoreClick}
            >
              Load More
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default Accordion;
