import React from "react";
import "./Frame.css";
import img1 from "./../../../Assets/iPhone12propremium.png"
import img2 from "./../../../Assets/Blue.png"
const Frame = () => {
  return (
    <>
      <div className="frame-1">
        <div className="frame-1-Left">
          <div className="f-1-Heading">
            List <span className="your">your</span>
            <br />
            Brand Offer Locally
          </div>
          <div className="f-1-subHeading">
            {/* - <span className="AWC">As we come </span> */}
            Discover the power of community support and local economy by listing your brand locally. Joining forces with neighborhood businesses fosters a stronger sense of connection and encourages sustainable growth, benefiting both your brand and your community.
          </div>
        </div>
        <div className="frame-1-Right">
          <img src={img1} ></img>
        </div>
      </div>
     
      <div className="frame-2">
        <div className="frame-2-Right">
          <img src={img2} style={{height:"90%", width:"50%"}}></img>
        </div>
        <div className="frame-2-Left">
          <div className="f-2-Heading">
            Get  <span className="of">Insight</span>
            <br />
            on Offer Interactions
          </div>
          <div className="mobile-f2-heading">
            <div>Get</div>
            <div>Insight</div>
            <div> of</div>
            <div>offer</div>
            <div>Interactions</div>
          </div>
          <div className="f-2-subHeading">
            {/* - <span className="AWC">As we come </span> */}
            Unlock valuable insights into customer behavior and preferences by tracking offer interactions. Understanding how customers engage with your offers enables you to tailor promotions effectively, maximize conversions, and ultimately boost your bottom line with precision.
          </div>
          <div className="f-3-subHeading">
            - <span className="AWC">As we move </span>
            into a future built on virtualization and digitalization, we are starting to lose our sense of the real world, opting instead for virtual friends and connections. The allure of the virtual world has led to a growing addiction, distancing us from authentic human interactions. Our mission is to revive the meet-and-greet culture, encouraging people to socialize and form genuine connections in the real world.
          </div>
        </div>
      </div>
    </>
  );
};

export default Frame;
