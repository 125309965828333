import React, { useState } from "react";
import "./foam.css";
import Navbar from "../../Common/Navbar/Navbar";
import Accordion from "../MainScreen/Accordion/Accordion";
import Footer from "../../Common/Footer/Footer";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NonprofitOrganization = () => {
  const [state, setState] = useState([]);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [businessName, setbusinessName] = useState("");
  const [area, setArea] = useState("");
  const [pincode, setPincode] = useState("");
  const [country, setCountry] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        `http://192.168.1.3:3000/admin/registerbusinessorg/createregbusiness`,
        {
          method: "POST",
          headers: {
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0ODJlNDdhYThiOWRjODhhYzU4NTRkZiIsImlhdCI6MTY4NjMwMDEyMiwiZXhwIjoxNjk0MDc2MTIyfQ.gRGPjduj2EdDkviIqvcth3FSPkIsCbHkZUFal9a7d5A",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            first_name: fname,
            last_name: lname,
            email: email,
            phonenumber: phone,
            businessName: businessName,
            area: area,
            pincode: pincode,
            state: state,
            country: country,
            email: email,
          }),
        }
      );
      const res = await response.json();
      console.log(res);
      if (response.ok) {
        // Successful registration logic
        toast.success("Registration Successful", {
          position: "top-right",
          autoClose: 3000, // 3 seconds
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // navigate("/non-profit-organization");
        setTimeout(function () {
          window.location.reload();
        }, 1000);
      } else {
        // Handle registration error
        toast.error("Registration failed.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.error("Registration failed.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast.error("An error occurred.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div>
      <Navbar />
      <div class="formbold-main-wrapper">
        <div class="formbold-form-wrapper">
          {/* <!-- <img src="your-image-url-here.jpg"> --> */}
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <form onSubmit={handleSubmit}>
            <div class="formbold-form-title">
              <h2 class="">Register for Business Organization</h2>
            </div>

            <div class="formbold-input-flex">
              <div>
                <label for="firstname" class="formbold-form-label">
                  First name
                </label>
                <input
                  type="text"
                  name="firstname"
                  id="first_name"
                  class="formbold-form-input"
                  value={fname}
                  onChange={(e) => setFname(e.target.value)}
                />
              </div>
              <div>
                <label for="lastname" class="formbold-form-label">
                  {" "}
                  Last name{" "}
                </label>
                <input
                  type="text"
                  name="lastname"
                  id="last_name"
                  class="formbold-form-input"
                  value={lname}
                  onChange={(e) => setLname(e.target.value)}
                />
              </div>
            </div>

            <div class="formbold-input-flex">
              <div>
                <label for="email" class="formbold-form-label">
                  {" "}
                  Email{" "}
                </label>
                <input
                  type="email"
                  name="email"
                  id="Email"
                  class="formbold-form-input"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <label for="phone" class="formbold-form-label">
                  {" "}
                  Phone number{" "}
                </label>
                <input
                  type="text"
                  name="phone"
                  id="phonenumber"
                  class="formbold-form-input"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>

            <div class="formbold-mb-3">
              <label for="ngo_name" class="formbold-form-label">
                Business Organization Name
              </label>
              <input
                type="text"
                name="business_name"
                id="business_name"
                class="formbold-form-input"
                value={businessName}
                onChange={(e) => setbusinessName(e.target.value)}
              />
            </div>

            <div class="formbold-input-flex">
              <div>
                <label for="state" class="formbold-form-label">
                  {" "}
                  State/Prvince{" "}
                </label>
                <input
                  type="text"
                  name="state"
                  id="state"
                  class="formbold-form-input"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                />
              </div>
              <div>
                <label for="country" class="formbold-form-label">
                  {" "}
                  Country{" "}
                </label>
                <input
                  type="text"
                  name="country"
                  id="country"
                  class="formbold-form-input"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                />
              </div>
            </div>

            <div class="formbold-input-flex">
              <div>
                <label for="post" class="formbold-form-label">
                  {" "}
                  Post/Zip code{" "}
                </label>
                <input
                  type="text"
                  name="post"
                  id="post"
                  class="formbold-form-input"
                  value={pincode}
                  onChange={(e) => setPincode(e.target.value)}
                />
              </div>
              <div>
                <label for="area" class="formbold-form-label">
                  {" "}
                  Area Code{" "}
                </label>
                <input
                  type="text"
                  name="area"
                  id="area"
                  class="formbold-form-input"
                  value={area}
                  onChange={(e) => setArea(e.target.value)}
                />
              </div>
            </div>

            {/* <div class="formbold-checkbox-wrapper">
              <label for="supportCheckbox" class="formbold-checkbox-label">
                <div class="formbold-relative">
                  <input
                    type="checkbox"
                    id="supportCheckbox"
                    className="formbold-input-checkbox"
                    name="agree"
                  />
                  <div class="formbold-checkbox-inner">
                    <span class="formbold-opacity-0">
                      <svg
                        width="11"
                        height="8"
                        viewBox="0 0 11 8"
                        fill="none"
                        class="formbold-stroke-current"
                      >
                        <path
                          d="M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z"
                          stroke-width="0.4"
                        ></path>
                      </svg>
                    </span>
                  </div>
                </div>
                I agree to the defined
                <a href="#"> terms, conditions, and policies</a>
              </label>
            </div> */}
            <button className="formbold-btn" onClick={handleSubmit}>
              Register Now
            </button>
          </form>
        </div>
      </div>
      <Accordion />
      <Footer />
    </div>
  );
};

export default NonprofitOrganization;
