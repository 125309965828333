import React from "react";
import "./Pricing.css";
import Navbar from "./../../Common/Navbar/Navbar";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
// import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Testimonials from "../MainScreen/Testimonials/Testimonials";
import Accordion from "../MainScreen/Accordion/Accordion";
import Footer from "../../Common/Footer/Footer";
// import { Margin } from "@mui/icons-material";
// import { Button } from "@mui/material";
const optionGreen1 = [
  {
    id: "0",
    svg: (
      <CheckCircleOutlineOutlinedIcon
        style={{ width: "18px", height: "18px", fill: "#2DC937" }}
      />
    ),
    name: "Upto 5 Advertisement videos",
  },
  {
    id: "1",
    svg: (
      <CheckCircleOutlineOutlinedIcon
        style={{ width: "18px", height: "18px", fill: "#2DC937" }}
      />
    ),
    name: "Free Ad Analytics",
  },

  {
    id: "2",
    svg: (
      <CheckCircleOutlineOutlinedIcon
        style={{ width: "18px", height: "18px", fill: "#2DC937" }}
      />
    ),
    name: "Publish Special offers in your locality",
  },
  {
    id: "3",
    svg: (
      <CheckCircleOutlineOutlinedIcon
        style={{ width: "18px", height: "18px", fill: "#2DC937" }}
      />
    ),
    name: "Neighbourhood Branding",
  },
  {
    id: "4",
    svg: (
      <CheckCircleOutlineOutlinedIcon
        style={{ width: "18px", height: "18px", fill: "#2DC937" }}
      />
    ),
    name: "Option to Publish in multiple localities",
  }
  // {
  //   id: "4",
  //   svg: (
  //     <CheckCircleOutlineOutlinedIcon
  //       style={{ width: "18px", height: "18px", fill: "#2DC937" }}
  //     />
  //   ),
  //   name: "Chat Support",
  // },
];
// const optionRed1 = [
//   {
//     id: "1",
//     svg: (
//       <CancelOutlinedIcon
//         style={{ width: "18px", height: "18px", fill: "#81A7BA" }}
//       />
//     ),
//     name: "Expense Tracking",
//   },
//   {
//     id: "2",
//     svg: (
//       <CancelOutlinedIcon
//         style={{ width: "18px", height: "18px", fill: "#81A7BA" }}
//       />
//     ),
//     name: "Invoice Generate",
//   },
//   {
//     id: "3",
//     svg: (
//       <CancelOutlinedIcon
//         style={{ width: "18px", height: "18px", fill: "#81A7BA" }}
//       />
//     ),
//     name: "Purchase Generate",
//   },
//   {
//     id: "4",
//     svg: (
//       <CancelOutlinedIcon
//         style={{ width: "18px", height: "18px", fill: "#81A7BA" }}
//       />
//     ),
//     name: "Payroll",
//   },
//   {
//     id: "5",
//     svg: (
//       <CancelOutlinedIcon
//         style={{ width: "18px", height: "18px", fill: "#81A7BA" }}
//       />
//     ),
//     name: "App Management",
//   },
// ];
const option2 = [
  {
    id: "0",
    svg: (
      <CheckCircleOutlineOutlinedIcon
        style={{ width: "18px", height: "18px", fill: "#2DC937" }}
      />
    ),
    name: "Upto 17 Advertisement videos",
  },
  {
    id: "1",
    svg: (
      <CheckCircleOutlineOutlinedIcon
        style={{ width: "18px", height: "18px", fill: "#2DC937" }}
      />
    ),
    name: "Free Ad Analytics",
  },

  {
    id: "2",
    svg: (
      <CheckCircleOutlineOutlinedIcon
        style={{ width: "18px", height: "18px", fill: "#2DC937" }}
      />
    ),
    name: "Publish Special offers in your locality",
  },
  {
    id: "3",
    svg: (
      <CheckCircleOutlineOutlinedIcon
        style={{ width: "18px", height: "18px", fill: "#2DC937" }}
      />
    ),
    name: "Neighbourhood Branding",
  },
  {
    id: "4",
    svg: (
      <CheckCircleOutlineOutlinedIcon
        style={{ width: "18px", height: "18px", fill: "#2DC937" }}
      />
    ),
    name: "Option to Publish in multiple localities",
  }
  // },
  // {
  //   id: "4",
  //   svg: (
  //     <CheckCircleOutlineOutlinedIcon
  //       style={{ width: "18px", height: "18px", fill: "#2DC937" }}
  //     />
  //   ),
  //   name: "Chat Support",
  // },
  // {
  //   id: "5",
  //   svg: (
  //     <CheckCircleOutlineOutlinedIcon
  //       style={{ width: "18px", height: "18px", fill: "#2DC937" }}
  //     />
  //   ),
  //   name: "Expense Tracking",
  // },
  // {
  //   id: "6",
  //   svg: (
  //     <CheckCircleOutlineOutlinedIcon
  //       style={{ width: "18px", height: "18px", fill: "#2DC937" }}
  //     />
  //   ),
  //   name: "Invoice Generate",
  // },
  // {
  //   id: "7",
  //   svg: (
  //     <CancelOutlinedIcon
  //       style={{ width: "18px", height: "18px", fill: "#81A7BA" }}
  //     />
  //   ),
  //   name: <s>Purchase Generate</s>,
  // },
  // {
  //   id: "8",
  //   svg: (
  //     <CancelOutlinedIcon
  //       style={{ width: "18px", height: "18px", fill: "#81A7BA" }}
  //     />
  //   ),
  //   name: <s>Payroll</s>,
  // },
  // {
  //   id: "9",
  //   svg: (
  //     <CancelOutlinedIcon
  //       style={{ width: "18px", height: "18px", fill: "#81A7BA" }}
  //     />
  //   ),
  //   name: <s>App Management</s>,
  // },
];
const option3 = [
  {
    id: "0",
    svg: (
      <CheckCircleOutlineOutlinedIcon
        style={{ width: "18px", height: "18px", fill: "#2DC937" }}
      />
    ),
    name: "Upto 75 Advertisement videos",
  },
  {
    id: "1",
    svg: (
      <CheckCircleOutlineOutlinedIcon
        style={{ width: "18px", height: "18px", fill: "#2DC937" }}
      />
    ),
    name: "Free Ad Analytics",
  },

  {
    id: "2",
    svg: (
      <CheckCircleOutlineOutlinedIcon
        style={{ width: "18px", height: "18px", fill: "#2DC937" }}
      />
    ),
    name: "Publish Special offers in your locality",
  },
  {
    id: "3",
    svg: (
      <CheckCircleOutlineOutlinedIcon
        style={{ width: "18px", height: "18px", fill: "#2DC937" }}
      />
    ),
    name: "Neighbourhood Branding",
  },
  {
    id: "4",
    svg: (
      <CheckCircleOutlineOutlinedIcon
        style={{ width: "18px", height: "18px", fill: "#2DC937" }}
      />
    ),
    name: "Option to Publish in multiple localities",
  }
  // {
  //   id: "5",
  //   svg: (
  //     <CheckCircleOutlineOutlinedIcon
  //       style={{ width: "18px", height: "18px", fill: "#2DC937" }}
  //     />
  //   ),
  //   name: "Expense Tracking",
  // },
  // {
  //   id: "6",
  //   svg: (
  //     <CheckCircleOutlineOutlinedIcon
  //       style={{ width: "18px", height: "18px", fill: "#2DC937" }}
  //     />
  //   ),
  //   name: "Invoice Generate",
  // },
  // {
  //   id: "7",
  //   svg: (
  //     <CheckCircleOutlineOutlinedIcon
  //       style={{ width: "18px", height: "18px", fill: "#2DC937" }}
  //     />
  //   ),
  //   name: "Purchase Generate",
  // },
  // {
  //   id: "8",
  //   svg: (
  //     <CheckCircleOutlineOutlinedIcon
  //       style={{ width: "18px", height: "18px", fill: "#2DC937" }}
  //     />
  //   ),
  //   name: "Payroll",
  // },
  // {
  //   id: "9",
  //   svg: (
  //     <CheckCircleOutlineOutlinedIcon
  //       style={{ width: "18px", height: "18px", fill: "#2DC937" }}
  //     />
  //   ),
  //   name: "App Management",
  // },
];
//

const Pricing = () => {
  return (
    <>
    <div className="darr">
    <Navbar/>
    </div>
      <div>
        {/* <div className="pricing-header">
          <p className="pricing-text">Choose Your Plan</p>
        </div> */}
        {/* <div className="p-options">
          <div className="p-rectangle-container">
            <div className="rect-1">
              <p className="r-1-text">Individual</p>
            </div>
            <div className="rect-2">
            <p className="r-2-text">Business</p>
            </div>
            </div>
          </div> */}
        {/* <div className="pc-sub-heading">
          <p className="pricing-subtext">
            Best Plans For{" "}
            <span className="pc-sub-span">Retail Businesses</span>
            </p>
          </div> */}
        <div className="pricing-card-group">
          <div className="pc-card-1">
            <div className="pc-1-container">
              <div className="pc-1-heading">
                <div className="pc-1-h-text">Silver</div>
              </div>
            </div>
            <div className="pc-1-container">
              <div className="pc-1-price">
              <div
                  className="Price_GST"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <div style={{ fontSize: "45px", color: "#c0c0c0" }}>
                    ₹
                    <s
                      style={{
                        fontSize: "45px",
                        color: "#c0c0c0",
                        fontWeight: "600",
                      }}
                    >
                      300
                    </s>
                  </div>
                  <div
                    className="GST-2"
                    style={{
                      color: "#c0c0c0",
                    }}
                    >
                    +GST
                  </div>
                </div>
                <div className="Price_GST">
                  <div className="pc-1-number">₹199</div>
                  <div className="GST">+GST</div>
                </div>
                <div className="number-text">5 Ads/Monthly</div>
              </div>
            </div>
            <div className="option-container">
              <div className="pc-1-options">
                {optionGreen1.map((option) => (
                  <div key={option.id} className="pc-options">
                    <div className="pc-vector">{option.svg}</div>
                    <div className="pc-text-green">{option.name}</div>
                  </div>
                ))}
                {/* {optionRed1.map((option) => (
                  <div key={option.id} className="pc-options">
                  <div className="pc-vector">{option.svg}</div>
                  <div className="pc-text-red">
                  <s>{option.name}</s>
                    </div>
                  </div>
                ))} */}
              </div>
            </div>
            {/* <div className="purchase-button-container">
              <div className="purchase-button">
              <button className="purchase-text">Start 1 month trial</button>
              </div>
            </div> */}
          </div>
          <div className="pc-card-1">
            <div className="pc-1-container">
              <div className="pc-1-heading">
                <div className="pc-1-h-text">Gold</div>
              </div>
            </div>
            <div className="pc-1-container">
              <div className="pc-1-price">
                <div
                  className="Price_GST"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <div style={{ fontSize: "45px", color: "#c0c0c0" }}>
                    ₹
                    <s
                      style={{
                        fontSize: "45px",
                        color: "#c0c0c0",
                        fontWeight: "600",
                      }}
                    >
                      800
                    </s>
                  </div>
                  <div
                    className="GST-2"
                    style={{
                      color: "#c0c0c0",
                    }}
                    >
                    +GST
                  </div>
                </div>
                <div className="Price_GST">
                  <div className="pc-1-number">₹499</div>
                  <div className="GST">+GST</div>
                </div>
                <div className="number-text">17 ads/Quarterly</div>
              </div>
            </div>
            <div className="option-container">
              <div className="pc-1-options">
                {option2.map((option) => (
                  <div key={option.id} className="pc-options">
                    <div className="pc-vector">{option.svg}</div>
                    <div className="pc-text-green">{option.name}</div>
                  </div>
                ))}
              </div>
            </div>
            {/* <div className="purchase-button-container">
              <div className="purchase-button">
              <button className="purchase-text">Start 1 month trial</button>
              </div>
            </div> */}
          </div>
          <div className="pc-card-1">
            <div className="pc-1-container">
              <div className="pc-1-heading">
                <div className="pc-1-h-text">Platinum</div>
              </div>
            </div>
            <div className="pc-1-container">
              <div className="pc-1-price">
                <div
                  className="Price_GST"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <div style={{ fontSize: "45px", color: "#c0c0c0" }}>
                    ₹
                    <s
                      style={{
                        fontSize: "45px",
                        color: "#c0c0c0",
                        fontWeight: "600",
                      }}
                    >
                      2400
                    </s>
                  </div>
                  <div
                    className="GST-2"
                    style={{
                      color: "#c0c0c0",
                    }}
                    >
                    +GST
                  </div>
                </div>
                <div className="Price_GST">
                  <div className="pc-1-number">₹1499</div>
                  <div className="GST">+GST</div>
                </div>
                <div className="number-text">75 ads/Yearly</div>
              </div>
            </div>
            <div className="option-container">
              <div className="pc-1-options">
                {option3.map((option) => (
                  <div key={option.id} className="pc-options">
                    <div className="pc-vector">{option.svg}</div>
                    <div className="pc-text-green">{option.name}</div>
                  </div>
                ))}
              </div>
            </div>
            {/* <div className="purchase-button-container">
              <div className="purchase-button">
              <button className="purchase-text">Start 1 month trial</button>
              </div>
            </div> */}
          </div>
        </div>
        {/* <div className="detail-plan-container">
          <div className="detail-plan">
            <div className="detail-plan-text">
              Elevate your local presence with APNA KONNECT Premium's dedicated
              "My Market" sections. Gain access to analytics, full content
              control, and seamless post-editing of your market posts as a
              premium member. Perfect for service providers, trained
              professionals, small/new businesses, and retail outlets looking to
              enhance neighbourhood/local outreach and drive footfall. Join as a
              Premium member today.
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button class="btn getstarted-btn">
            <a href="/installApp">Experience APNA KONNECT</a>
          </button>
        </div> */}
        <Testimonials />
        {/* <Accordion /> */}
        <Footer />
      </div>
     
    </>
          
  );
};

export default Pricing;
