import React, { useEffect, useState } from "react";
import "./stories.css";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import Slider from "react-slick";
const Stories = () => {
  const card = [
    {
      id: 1,
      image:
        "https://t4.ftcdn.net/jpg/03/64/21/11/360_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
      client_name: "Sarah K.",
      client_post: "UX Designer @Brello",
      client_review: `I was looking for a way to streamline my design process and the
                  Anima's Landing Page UI Kit was a lifesaver! The intuitive
                  design and ease of customisation have saved me hours of time and
                  effort. Highly recommend!`,
    },
    {
      id: 2,
      image:
        "https://wisehealthynwealthy.com/wp-content/uploads/2022/01/CreativecaptionsforFacebookprofilepictures.jpg",
      client_name: "Sarah K.",
      client_post: "UX Designer @Brello",
      client_review: `I was looking for a way to streamline my design process and the
                  Anima's Landing Page UI Kit was a lifesaver! The intuitive
                  design and ease of customisation have saved me hours of time and
                  effort. Highly recommend!`,
    },
    {
      id: 3,
      image:
        "https://t4.ftcdn.net/jpg/03/64/21/11/360_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
      client_name: "Sarah K.",
      client_post: "UX Designer @Brello",
      client_review: `I was looking for a way to streamline my design process and the
                  Anima's Landing Page UI Kit was a lifesaver! The intuitive
                  design and ease of customisation have saved me hours of time and
                  effort. Highly recommend!`,
    },
    {
      id: 4,
      image:
        "https://t4.ftcdn.net/jpg/03/64/21/11/360_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
      client_name: "Sarah K. 2",
      client_post: "UX Designer @Brello",
      client_review: `I was looking for a way to streamline my design process and the
                  Anima's Landing Page UI Kit was a lifesaver! The intuitive
                  design and ease of customisation have saved me hours of time and
                  effort. Highly recommend!`,
    },
    {
      id: 5,
      image:
        "https://wisehealthynwealthy.com/wp-content/uploads/2022/01/CreativecaptionsforFacebookprofilepictures.jpg",
      client_name: "Sarah K. 2",
      client_post: "UX Designer @Brello",
      client_review: `I was looking for a way to streamline my design process and the
                  Anima's Landing Page UI Kit was a lifesaver! The intuitive
                  design and ease of customisation have saved me hours of time and
                  effort. Highly recommend!`,
    },
    {
      id: 6,
      image:
        "https://t4.ftcdn.net/jpg/03/64/21/11/360_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
      client_name: "Sarah K. 2",
      client_post: "UX Designer @Brello",
      client_review: `I was looking for a way to streamline my design process and the
                  Anima's Landing Page UI Kit was a lifesaver! The intuitive
                  design and ease of customisation have saved me hours of time and
                  effort. Highly recommend!`,
    },
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % Math.ceil(card.length / 3));
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };
  useEffect(() => {
    const autoplayTimer = setInterval(() => {
      handleNext();
    }, 5000); // Change the slide every 5 seconds (5000 milliseconds)

    return () => {
      clearInterval(autoplayTimer);
    };
  }, [activeIndex]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],

    beforeChange: (current, next) => {},
  };

  return (
    <div className="stories">
      <div className="stories-header">
        <div className="Features-heading">
          Real Stories from Satisfied Customers
        </div>
        <div className="Features-subHeading">
          See how our landing page UI kit is making an impact.
        </div>
      </div>
      <div className="stories-card-slider">
        <Slider {...settings}>
          {card.map((item) => (
            <div key={item.id} className="stories-card">
              <div className="story-card-info">
                <div className="client-info">
                  <div className="client-image-container">
                    <img className="client-image" src={item.image} alt="" />
                  </div>
                  <div className="client-details">
                    <div className="client-name">{item.client_name}</div>
                    <div className="client-post">{item.client_post}</div>
                  </div>
                </div>
                <div className="client-review-container">
                  <div className="client-review">{item.client_review}</div>
                </div>
                <div className="star-rating-section">
                  <AiFillStar className="star" />
                  <AiFillStar className="star" />
                  <AiFillStar className="star" />
                  <AiFillStar className="star" />
                  <AiOutlineStar className="star" />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Stories;
