import React from "react";
import "./Testimonials.css";
import ReactPlayer from "react-player";
import { AiFillStar } from "react-icons/ai";
import SwiperCore from "swiper";
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

// Register Swiper modules
SwiperCore.use([Pagination, Autoplay]);

const Testimonials = () => {
  return (
    <div className="p_testimonials">
      <div className="p_testimonials-row">
        <div className="p_testimonials-video">
          <div className="mockup-window border">
            <div className="flex bg-base-200 video-testimonial">
              <ReactPlayer
                url="https://www.youtube.com/shorts/7Zh0UHj__Jo"
                width="80%"
                height="100%"
                controls
                playing
                muted
                loop
              />
            </div>
          </div>
        </div>
        <div className="p_testimonials-cards-review">
          <Swiper
            slidesPerView={'auto'}
            centeredSlides={true}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false
            }}
            
            className="mySwiper_p_testimonials"
          >
            <SwiperSlide>
              <div className="p_testimonial-card-info">
                <div className="p_testimonial-client-info">
                  <div className="p_clint-info">
                    <div className="p_client-name">Ajay Pandit</div>
                  </div>
                  <div className="star-rating-section">
                    <AiFillStar className="star" />
                    <AiFillStar className="star" />
                    <AiFillStar className="star" />
                    <AiFillStar className="star" />
                    <AiFillStar className="star" />
                  </div>
                </div>
                <div className="p_client-review">
                  <div className="p_review">
                    "Nice application for making digital India"
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="p_testimonial-card-info">
                <div className="p_testimonial-client-info">
                  <div className="p_clint-info">
                    <div className="p_client-name">Vaghela Mohit</div>
                  </div>
                  <div className="star-rating-section">
                    <AiFillStar className="star" />
                    <AiFillStar className="star" />
                    <AiFillStar className="star" />
                    <AiFillStar className="star" />
                    <AiFillStar className="star" />
                  </div>
                </div>
                <div className="p_client-review">
                  <div className="p_review">
                    "It's a very useful app to grow your business in your locality. Great app and great customer service."
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="p_testimonial-card-info">
                <div className="p_testimonial-client-info">
                  <div className="p_clint-info">
                    <div className="p_client-name">Pavan Novelty</div>
                  </div>
                  <div className="star-rating-section">
                    <AiFillStar className="star" />
                    <AiFillStar className="star" />
                    <AiFillStar className="star" />
                    <AiFillStar className="star" />
                    <AiFillStar className="star" />
                  </div>
                </div>
                <div className="p_client-review">
                  <div className="p_review">
                    "This app helps a lot with expanding the business."
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="p_testimonial-card-info">
                <div className="p_testimonial-client-info">
                  <div className="p_clint-info">
                    <div className="p_client-name">Dhiral Makwana</div>
                  </div>
                  <div className="star-rating-section">
                    <AiFillStar className="star" />
                    <AiFillStar className="star" />
                    <AiFillStar className="star" />
                    <AiFillStar className="star" />
                    <AiFillStar className="star" />
                  </div>
                </div>
                <div className="p_client-review">
                  <div className="p_review">
                    "The best app for business owners to get new clients in their own area."
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
