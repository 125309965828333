import React from "react";
import "./Marketplace.css";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import image from "../../../Assets/MainScreen/meetpeople.avif";
import dance from "../../../Assets/dance.jpeg";
import food from "../../../Assets/food.jpeg";
import sr from "../../../Assets/sr.jpeg";
import com from "../../../Assets/com.jpeg";
import MomChild from "../../../Assets/MainScreen/momchild.avif";
import Playing from "../../../Assets/MainScreen/playing.avif";
const Marketplace = () => {
  return (
    <>
      <div className="market-place-main">
        <div>
          <div className="mp-top">
            <div className="mp-top-container">
              <div style={{ marginTop: "180px" }}>
                <img src={com} className="mp-top-image" />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "10px",
                  }}
                >
                  <img src={Playing} className="mp-top-image-2" />
                </div>
              </div>
              <div>
                <h1 className="nearby-heading">Connect your locality like never before</h1>
                <p className="nearby-subheading">
                It's an innovative initiative designed to revolutionize community engagement and connectivity within neighborhoods. 
                By harnessing the power of technology and digital platforms, this initiative aims to foster stronger bonds among residents, 
                promote collaboration, and enhance the overall sense of community.
                </p>
                <a
                  className=""
                  style={{ color: "blue" }}
                  href="https://www.w3schools.com/css/css_padding.asp"
                >
                  {/* <u> Get Started &rarr;</u> */}
                </a>
              </div>
            </div>
          </div>
          <div className="market-place">
            <div className="market-place-card">
              <div className="mp-card-container">
                <img className="mp-card" src={dance} />
              </div>
              <div className="mp-card-container-text">
                <div className="mp-card-container-text-heading">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="mp-card-subheading">
                      Dance Community
                    </div>
                    <div className="mp-card-subheading">
                      <FavoriteBorderIcon />
                    </div>
                  </div>
                  {/* <p className="mp-card-heading">Jocelyn Schleifer</p> */}
                  <p className="mp-card-para">
                  Dance together, shine together! Join our inclusive community where passion meets rhythm. 
                  All levels welcome. Let's groove!
                  </p>
                </div>
              </div>
            </div>
            <div className="market-place-card">
              <div className="mp-card-container">
                <img className="mp-card" src={food} />
              </div>
              <div className="mp-card-container-text">
                <div className="mp-card-container-text-heading">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div className="mp-card-subheading">
                      Food Comumunity
                    </div>
                    <div className="mp-card-subheading">
                      <FavoriteIcon style={{ color: "lightgreen" }} />
                    </div>
                  </div>
                  {/* <p className="mp-card-heading">Jocelyn Schleifer</p> */}
                  <p className="mp-card-para">
                  Join fellow foodies to share recipes, tips, and culinary adventures. 
                  From cooking novices to seasoned chefs, all are welcome.
                  </p>
                </div>
              </div>
              {/* <div className="mp-card-social-icons">
                <div className="mp-card-icons">
                  <div className="mp-card-icon-1">
                    <p>€10</p>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="market-place-card">
              <div className="mp-card-container">
                <img className="mp-card" src={sr} />
              </div>
              <div className="mp-card-container-text">
                <div className="mp-card-container-text-heading">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div className="mp-card-subheading">
                      Senior Support Community
                    </div>
                    <div className="mp-card-subheading">
                      <FavoriteBorderIcon />
                    </div>
                  </div>
                  {/* <p className="mp-card-heading">Jocelyn Schleifer</p> */}
                  <p className="mp-card-para">
                  Whether navigating health challenges, retirement, or simply seeking firnds, 
                  we're here to listen, share, and uplift in life's journey.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="last-img">
          <img className="last-image" src={MomChild} />
        </div>
      </div>
      <div className="scrollable-container">
        <div className="n-mobile-scroll">
          <div style={{ marginTop: "10px" }}>
            <img src={image} className="mp-top-image-mobile" />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "10px",
              }}
            >
              <img src={Playing} className="mp-top-image-2-mobile" />
            </div>
          </div>
       
          <div className="last-img-mobile">
            <img className="last-image-mobile" src={MomChild} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Marketplace;
