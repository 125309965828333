import React from "react";
import Navbar from "../../Navbar/Navbar";
import Footer from "../../Footer/Footer";
import "./RCpolicy.css";
const RCPolicy = () => {
  return (
    <div className="back-color">
      <Navbar />
      <div className="rc">
        <div className="rc-header">
          <h1 className="rc-h1">Refund / Cancellation Policy</h1>
        </div>
        <div>
          <h3 className="rc-h3">1. Subscription Cancellation :</h3>
          <p className="rc-p">
            Customers who have enrolled in the pay-as-you-go premium services
            provided by Apna Konnect have the flexibility to discontinue their
            services at any time without being bound by a long-term contract.
          </p>

          <h3 className="rc-h3">2. Refund Eligibility :</h3>
          <p className="rc-p">
            {" "}
            a. Customers who have availed pay-as-you-go premium services from
            Apna Konnect are not eligible for refunds due to the nature of the
            model. This is because customers are only charged for the specific
            services rendered during the active billing cycle.
          </p>
          <p className="rc-p">
            b. However, if any charges have been incurred for services not
            delivered as agreed upon, Apna Konnect will investigate the issue
            and provide appropriate adjustments or credits toward future
            services.
          </p>

          <h3 className="rc-h3">3. Refund Process :</h3>
          <p className="rc-p">
            To request a refund, customers must follow these steps:
          </p>
          <p className="rc-p">
            a. Contact our Customer Support team via email or phone to request a
            refund.
          </p>
          <p className="rc-p">
            b. Provide the necessary details, including the reason for the
            cancellation and the original purchase information.
          </p>
          <p className="rc-p">
            c. Our Customer Support team will review the request and respond
            within 10 - 15 business days.
          </p>
          <p className="rc-p">
            d. If the refund is approved, the refund amount will be processed by
            the company to the user.
          </p>

          <h3 className="rc-h3">4. Cancellation Process :</h3>
          <p className="rc-p">
            By going into the app opening their premium profile and clicking on
            the “cancel subscription” button.
          </p>

          <h3 className="rc-h3">5. Modifications to Services :</h3>
          <p className="rc-p">
            Apna Konnect reserves the right to modify, suspend, or discontinue
            any specific services within the pay-as-you-go model. Customers will
            be notified of any such changes in advance, and any charges for
            discontinued services will be prorated accordingly.
          </p>

          <h3 className="rc-h3">6. Contact Information :</h3>
          <p className="rc-p">
            For any questions or concerns related to our Refund / Cancellation
            Policy for the premium pay-as-you-go model, please contact our
            Customer Support team:
          </p>
          <p>
            <span
              style={{ fontSize: "16px", color: "#225c7b", fontWeight: "900" }}
            >
              Email :
            </span>
            <a href="mailto:resshucoonnect@gmail.com">resshucoonnect@gmail.com</a>
          </p>
          <p>
            <span
              style={{ fontSize: "16px", color: "#225c7b", fontWeight: "900" }}
            >
              Phone :
            </span>
            <a href="tel:9909959447">9909959447</a>
          </p>
          <h4 style={{ color: "red", marginTop: "30px" }}>
            Note: This policy is subject to change at the discretion of Apna
            Konnect. Customers will be informed of any changes to the policy in
            advance. Please consult the most recent version of the policy on our
            official website.
          </h4>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RCPolicy;
