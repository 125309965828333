import React from "react";
import ReactPlayer from "react-player";
import "./video.css";
// import video from "../../../Assets/Videos/Apnakonnectvideo.mp4"
const Video = ({url,width,height}) => {
  console.log(url);
  return (
    <div  class="SM DM" data-observe-resizes>
    <div className="p_video">
      <div className="p_video-container">
        <div className="mockup-window border-20">
          <div className="flex justify-center align-center video-frame">
            <ReactPlayer
              url={url} // Replace with your video URL
              width={width || "100vw"}
              height={height || "100%"}
              controls // Show video controls (play, pause, etc.)
              playing // Start playing the video automatically
              muted // Mute the video
              loop // Loop the video when it ends
            />
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Video;
