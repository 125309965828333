import React from "react";
import "./Pheading.css";
const PHeading = () => {
  return (
    <div className="p_Banner">
      <div className="background">
        <div className="pattern">
          <svg
            width="118"
            height="131"
            viewBox="0 0 118 131"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M88.8698 0.31714C104.089 12.034 114.03 29.3166 116.507 48.3632C118.983 67.4098 113.792 86.66 102.075 101.879C90.3583 117.098 73.0757 127.039 54.0291 129.516C34.9825 131.992 15.7323 126.801 0.513201 115.084L21.2297 88.1756C29.3121 94.398 39.5354 97.1549 49.6504 95.8397C59.7655 94.5245 68.9438 89.245 75.1662 81.1626C81.3887 73.0802 84.1456 62.857 82.8304 52.7419C81.5152 42.6268 76.2357 33.4485 68.1533 27.2261L88.8698 0.31714Z"
              fill="url(#paint0_linear_276_10089)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_276_10089"
                x1="12.4682"
                y1="43.0386"
                x2="116.998"
                y2="109.938"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#00FFAA" stop-opacity="0.57" />
                <stop offset="0.995857" stop-color="#066042" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="background-circle"></div>
        <div className="pattern-circle"></div>
        <div className="banner-text-container">
          <div className="text-container-banner">
            <div className="banner-text">Premium Profile</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PHeading;
